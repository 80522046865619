import React, { useEffect, useState } from 'react';

// // Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from '../../Components/Commons/Loader/Loader';
import MoreInformation from './Components/MoreInformation/MoreInformation';
import Deposits from './Components/Deposits/Deposits';
import History from './Components/History/History';
import FinancialReport from './Components/FinancialReport/FinancialReport';
import Packages from './Components/Packages/Packages';

// // Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';

// Styles
import Style from './UserDetails.module.css';

const UserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('id');

  const [tab, setTab] = useState('profile');

  const moreInfo = useQuery({
    url: `administration/users/details/`,
    method: 'POST',
    executeImmediately: false,
  });

  useEffect(() => {
    document.title = 'PULSE WORLD | User Details';
    moreInfo.executeQuery({
      id: userId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      firstApiLoading={moreInfo.isLoading}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <section className={Style.userInfo}>
        <Heading viewTitle='User Details' />
        {moreInfo.response ? (
          <>
            <h5 className={Style.username}>
              Username: {moreInfo.response.data.username}
            </h5>
            <div className={Style.filter}>
              <div
                className={tab === 'profile' ? Style.filterSelected : undefined}
                onClick={() => {
                  setTab('profile');
                }}
              >
                Profile
              </div>
              <div
                className={
                  tab === 'deposits' ? Style.filterSelected : undefined
                }
                onClick={() => {
                  setTab('deposits');
                }}
              >
                Deposits
              </div>
              <div
                className={
                  tab === 'history' ? Style.filterSelected : undefined
                }
                onClick={() => {
                  setTab('history');
                }}
              >
                History
              </div>
              <div
                className={
                  tab === 'report' ? Style.filterSelected : undefined
                }
                onClick={() => {
                  setTab('report');
                }}
              >
                Financial report
              </div>
              <div
                className={
                  tab === 'packages' ? Style.filterSelected : undefined
                }
                onClick={() => {
                  setTab('packages');
                }}
              >
                Packages
              </div>
            </div>

            {tab === 'profile' && (
              <MoreInformation {...moreInfo.response.data} moreInfo={moreInfo} userId={userId}/>
            )}
            {tab === 'deposits' && <Deposits id={userId} />}
            {tab === 'history' && <History id={userId} />}
            {tab === 'report' && <FinancialReport id={userId} />}
            {tab === 'packages' && <Packages id={userId} />}
          </>
        ) : (
          <p>An error occurred. Go back to the user list</p>
        )}
      </section>
    </PermissionRoute>
  );
};

export default UserDetails;
