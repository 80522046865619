import React, { useEffect } from 'react'

// Libraries
import { useNavigate } from 'react-router-dom'

// Images
import NotFound from '../../../Assets/images/404.png'
import LogoPulseFull from '../../../Assets/images/logos/logo-pulse-full.png'

// Styles
import Style from './Page404.module.css'

const Page404 = () => {


  useEffect(() => {
    document.title = 'PULSE WORLD | Page Not Found'
  }, [])

  const navigate = useNavigate();

  return (
    <section className={Style.page}>
      <img src={LogoPulseFull} alt="" className={Style.logo} style={{ cursor: "pointer" }} onClick={() => { navigate("/") }} />
      <div className={Style.center}>
        <img src={NotFound} alt="" />
        <div className={Style.text}>
          <h1>Page Not Found</h1>
          {/* <h4>{subTitle}</h4> */}
        </div>
      </div>
    </section>
  )
}

export default Page404