import React, { useState } from "react";

// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Contexts
import PermissionsContext from "./Contexts/PermissionsContext";

// Layouts
import MainLayout from "./Layouts/MainLayout/MainLayout";
import AuthLayout from './Layouts/AuthLayout/AuthLayout';

// General Views
import Page404 from "./Views/ServicePages/Page404/Page404";
import Logout from "./Views/Auth/Logout/Logout";
import Login from "./Views/Auth/Login/Login";
import Dashboard from "./Views/Dashboard/Dashboard";
import Deposits from "./Views/Deposits/Deposits";
import DepositDetails from "./Views/DepositDetails/DepositDetails";
import UserList from "./Views/UserList/UserList";
import UserBlocked from "./Views/UserBlocked/UserBlocked";
import Purchased from "./Views/Purchased/Purchased";
import Withdraw from "./Views/Withdraw/Withdraw";
import Report from "./Views/Report/Report";
import UserDetails from "./Views/UserDetails/UserDetails";
import ReportDetails from "./Views/ReportDetails/ReportDetails";
import CreateAdmin from "./Views/CreateAdmin/CreateAdmin";
import TwoFactor from "./Views/Auth/TwoFactor/TwoFactor";
import AdminList from "./Views/AdminList/AdminList";
import SiteSettings from "./Views/SiteSettings/SiteSettings";
import FinancialReport from "./Views/FinancialReport/FinancialReport";
import PlatformPopup from "./Views/PlatformPopup/PlatformPopup";
// import Balance from "./Views/Balance/Balance";
import Export from "./Views/Export/Export";

// Styles
import "./Assets/css/index.css";

const App = () => {

    const [permissions, setPermissions] = useState({})

    return (
        <PermissionsContext.Provider value={[permissions, setPermissions]}>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Page404 />} />

                    <Route path="/auth" element={<AuthLayout />}>
                        <Route path="login" element={<Login />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="two-factor" element={<TwoFactor />} />
                    </Route>

                    <Route path="/" element={<MainLayout />}>
                        <Route path="" element={<Dashboard />} />
                        <Route path="user-list" element={<UserList />} />
                        <Route path="user-details" element={<UserDetails />} />
                        <Route path="user-locked" element={<UserBlocked />} />
                        <Route path="report" element={<Report />} />
                        <Route path="report-details" element={<ReportDetails />} />
                        <Route path="financial-report" element={<FinancialReport />} />
                        <Route path="purchased" element={<Purchased />} />
                        <Route path="create-admin" element={<CreateAdmin />} />
                        <Route path="admin-list" element={<AdminList />} />
                        <Route path="platform-popup" element={<PlatformPopup />} />
                        {/* <Route path="balance-request" element={<Balance />} /> */}
                        <Route path="export-list" element={<Export />} />
                        <Route path="withdraw" element={<Withdraw />} />
                        <Route path="deposits" element={<Deposits />} />
                        <Route path="deposit-details" element={<DepositDetails />} />
                        <Route path="admin-list" element={<AdminList />} />
                        <Route path="site-settings" element={<SiteSettings />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </PermissionsContext.Provider >
    );
};

export default App;
