import React, { useState } from 'react'

// Icons
import { IoIosArrowDown } from 'react-icons/io'

// Styles
import Style from "./FilterForTime.module.css";

const FilterForTime = ({ data, setYear, setMonth, setDay, month, year, style }) => {

    // Cerca il nome in data passando mese ed anno
    const getSelected = (month, year) => {
        const selected = data.find((item) => item.month === month && item.year === year);
        return selected ? selected.name : null;
    }

    const [openDropdown, setOpenDropdown] = useState(false);
    const [selected, setSelected] = useState(getSelected(month, year) || data[data.length - 1].name);

    return (
        <div
            className={Style.select}
            style={style}
            onClick={() => {
                setOpenDropdown((oldState) => !oldState)
            }}
            onMouseLeave={() => setOpenDropdown(false)}
        >

            <div className={Style.selected}>
                <span>
                    {selected}
                </span>
                <span className={openDropdown ? Style.arrowOpened : Style.arrow}>
                    <IoIosArrowDown />
                </span>
            </div>
            <div
                style={openDropdown === true ? { maxHeight: "288px" } : { maxHeight: 0 }}
                className={openDropdown === true ? Style.optionsOpened : Style.options}
            >
                {data.map((item, index) => {
                    return (
                        selected !== item.name &&
                        <div
                            key={index}
                            onClick={() => {
                                setSelected(item.name);
                                setMonth(item.month);
                                setYear(item.year);
                                setDay(1);
                            }}
                        >
                            {item.name}
                        </div>
                    )
                })}
            </div>
        </div>

    )
}

export default FilterForTime