import React, { useEffect, useState, useContext } from 'react'

// Components
import Heading from '../../Components/Commons/Heading/Heading'
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from "../../Components/Commons/Loader/Loader";

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { Button } from "@hybris-software/ui-kit"
import { useNavigate, useLocation } from 'react-router-dom';
import styled from "styled-components";

// Context
import PermissionsContext from '../../Contexts/PermissionsContext';

// Utils
import userHasPermission from '../../Utils/userHasPermission';
import dispatcherRole from '../../Utils/dispatcherRole';

// Icons
import { MdBlock } from 'react-icons/md'
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineWarning } from 'react-icons/ai'
import { GrDocumentCsv } from 'react-icons/gr';

// Styles
import Style from "./Withdraw.module.css";

// Data
import { userActions } from '../../Data/constants';

const CommonStyles = styled.div`
  padding: 1rem;
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tbody tr {
      height: 50px;
      font-size: 13px;
      color: #595959;
      font-weight: 500;
      font-size: 16px;
    }
    th,
    td {
      padding: 20px 0 22px;
      position: relative;
      margin: 0;
      font-weight: 500;
      min-width: 200px;
      max-width: 500px;
      border-bottom: 1px solid #f0f0f0;
      text-align: left;
      :first-child {
        min-width: 60px;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Withdraw = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const statusParam = urlParams.get("status");

    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState(null);
    const [search, setSearch] = useState("");
    const [searchField, setSearchField] = useState("username");
    const [searchLoading, setSearchLoading] = useState(false);
    const [sortedBy, setSortedBy] = useState("createdAt");
    const [sortedType, setSortedType] = useState("desc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredDate, setFilteredDate] = useState([null, null]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [status, setStatus] = useState(statusParam ? statusParam : "");
    const [page, setPage] = useState(1);

    const [permissions] = useContext(PermissionsContext);

    const tableApi = useQuery({
        url: url,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data);
            setSearchLoading(false);
        },
        onError: (error) => {
            setSearchLoading(false);
        }
    });

    const exportCsvApi = useQuery({
        url: `administration/withdraws/export/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&status=${status}`,
        method: 'GET',
        executeImmediately: false,
        clientOptions: {
          timeout: 120000,
        },
        onSuccess: (response) => {
          const blob = new File([response.data], `purchased.csv`, {
            type: 'text/csv',
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        },
      });    

    const withdrawStatsApi = useQuery({
        url: "administration/stats/withdraws/",
        method: "GET",
        executeImmediately: true,
    });

    const checkWithdraw = useQuery({
        url: `administration/withdraws/approve/`,
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            tableApi.executeQuery();
            setSelectedIds([]);
        }
    });

    const columns = [
        {
            Header: " ",
            checkbox: true,
            accessor: (row) => {
                return (
                    <>
                        {row.status === "pending"
                            ? (
                                <input
                                    type="checkbox"
                                    checked={selectedIds.includes(row.id) ? true : false}
                                    onChange={(e) => {

                                        if (e.target.checked) {
                                            setSelectedIds((oldList) => {
                                                return [...oldList, row.id]
                                            })
                                        } else {
                                            setSelectedIds((oldList) => {
                                                return oldList.filter((id) => id !== row.id)
                                            })
                                        }
                                    }}
                                />
                            )
                            : (
                                <input
                                    type="checkbox"
                                    disabled={true}
                                    className={Style.disabledCheckbox}
                                />
                            )
                        }
                    </>

                )
            },
        },
        {
            Header: "Username",
            field: "username",
            filterfield: "username",
            accessor: (row) => { return row.username },
        },
        {
            Header: "Amount (after fee)",
            field: "amount",
            accessor: (row) => (`${row.amount} (${row.finalAmount})`),
        },
        {
            Header: "Date",
            field: "createdAt",
            accessor: (row) => { return row.createdAt },
        },
        {
            Header: "Status",
            field: "status",
            accessor: (row) => {
                if (row.status === "pending") {
                    return <div className={Style.pending}>
                        <MdBlock />Pending
                    </div>
                }
                else if (row.status === "completed") {
                    return <div className={Style.completed}>
                        <AiOutlineCheckCircle />Approved
                    </div>
                }
                else if (row.status === "cancelled") {
                    return <div className={Style.cancelled}>
                        <AiOutlineCloseCircle />Cancelled
                    </div>
                }
                else if (row.status === "failed") {
                    return <div className={Style.cancelled}>
                        <AiOutlineWarning />Failed
                    </div>
                }
                else {
                    return <div className={Style.cancelled}>
                        {row.status}
                    </div>
                }
            },
        },
        {
            Header: "Wallet",
            field: "address",
            accessor: (row) => { return row.address },
        },
    ]
    if (userHasPermission(permissions, userActions.approveWithdraw)) {
        columns.push(
            {
                Header: "Actions",
                accessor: (row) => {
                    return (

                        <div className={Style.actions}>
                            {row.status === "pending" ? (
                                <>
                                    <div
                                        className={Style.approvedButton}
                                        title="Approve"
                                        onClick={() => {
                                            checkWithdraw.executeQuery({
                                                ids: [row.id],
                                                accept: true
                                            })
                                        }}
                                    >
                                        <AiOutlineCheckCircle />
                                    </div>
                                    <div className={Style.rejectButton} title="Reject"
                                        onClick={() => {
                                            checkWithdraw.executeQuery({
                                                ids: [row.id],
                                                accept: false
                                            })
                                        }}
                                    >
                                        <AiOutlineCloseCircle />
                                    </div>
                                </>
                            )
                                : (
                                    "No actions"
                                )
                            }
                        </div >

                    )
                },
            },
        )
    }  

    useEffect(() => {
        setUrl(
            `administration/withdraws/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&limit=100&status=${status}&page=${page}`
        );
    }, [search, sortedBy, sortedType, searchField, filteredDate, status, page]);

    useEffect(() => {
        document.title = "PULSE WORLD | Withdraw";
        if (url) {
            tableApi.executeQuery();
            setSelectedIds([]);
        }
        //eslint-disable-next-line
    }, [url])

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            firstApiLoading={tableApi.isLoading || withdrawStatsApi.isLoading}
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate)
                return true
            }}
        >
            <section>
                <Heading viewTitle={"Withdraw"} />
                {withdrawStatsApi.response && <h5 className={Style.stats}>Airdrop wallet balance: {parseFloat(withdrawStatsApi.response.data.airdropWalletBalance).toFixed(4)}XPL</h5>}
                {tableData && (
                    <>
                        {userHasPermission(permissions, userActions.export) && (
                            <>
                                <Button
                                className={Style.export}
                                isLoading={exportCsvApi.isLoading}
                                onClick={() => {
                                    exportCsvApi.executeQuery();
                                }}
                                >
                                <GrDocumentCsv />
                                Download filtered CSV
                                </Button>
                                {exportCsvApi.isLoading && (
                                <div className={Style.exportMessage}>
                                    Exporting, please wait...
                                    <br />
                                    The process can take a few seconds.
                                </div>
                                )}
                                {exportCsvApi.isError && (
                                <div className={Style.exportMessage}>
                                    Export failed, try again later.
                                </div>
                                )}
                                {exportCsvApi.isSuccess && (
                                <div className={Style.exportMessage}>
                                    Export success. If the file has not been downloaded check that
                                    the browser is not blocking popups.
                                </div>
                                )}
                            </>
                        )}

                        <div className={Style.filterBar}>
                            <div className={Style.filter}>
                                <div
                                    className={status === "" ? Style.allStatus : undefined}
                                    onClick={() => { setStatus("") }}
                                >
                                    All
                                </div>
                                <div
                                    className={status === "pending" ? Style.pendingStatus : undefined}
                                    onClick={() => { setStatus("pending") }}
                                >
                                    Pending
                                </div>
                                <div
                                    className={status === "completed" ? Style.approvedStatus : undefined}
                                    onClick={() => { setStatus("completed") }}
                                >
                                    Approved
                                </div>
                                <div
                                    className={status === "failed" ? Style.approvedStatus : undefined}
                                    onClick={() => { setStatus("failed") }}
                                >
                                    Failed
                                </div>
                            </div>
                            {userHasPermission(permissions, userActions.approveWithdraw) && (
                                <div className={Style.buttons}>
                                    <Button
                                        className={Style.buttonApproveAll}
                                        disabled={selectedIds.length === 0}
                                        isLoading={checkWithdraw.isLoading}
                                        onClick={() => {
                                            checkWithdraw.executeQuery({
                                                ids: selectedIds,
                                                accept: true
                                            })
                                        }}
                                    >
                                        Approve selected
                                    </Button>
                                    <Button
                                        className={Style.buttonRejectAll}
                                        disabled={selectedIds.length === 0}
                                        isLoading={checkWithdraw.isLoading}
                                        onClick={() => {
                                            checkWithdraw.executeQuery({
                                                ids: selectedIds,
                                                accept: false
                                            })
                                        }}
                                    >
                                        Reject selected
                                    </Button>
                                </div>
                            )}
                        </div>

                        <GeneralTable
                            data={tableData}
                            columns={columns}
                            setPage={setPage}
                            setSearch={setSearch}
                            searchField={searchField}
                            setSearchField={setSearchField}
                            isLoading={tableApi.isLoading}
                            sortedBy={sortedBy}
                            setSortedBy={setSortedBy}
                            sortedType={sortedType}
                            setSortedType={setSortedType}
                            fitlerForDate={true}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filteredDate={filteredDate}
                            setFilteredDate={setFilteredDate}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            useCheckBox={true}
                            Styles={CommonStyles}
                            searchLoading={searchLoading}
                            setSearchLoading={setSearchLoading}
                        />
                    </>
                )}

                {tableApi.isError && (
                    <div style={{ marginTop: 30 }}>There was a problem generating this table</div>
                )}
            </section>
        </PermissionRoute>
    )
}

export default Withdraw