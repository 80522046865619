import React from 'react';

// Libraries
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "@hybris-software/ui-kit";
import { ApiProvider, generateApiClient } from '@hybris-software/use-query';
import { AuthProvider } from '@hybris-software/use-auth';

// Styles
import './Assets/css/index.css';
import Theme from './Assets/css/Theme.module.css';

// Data
import Config from './Data/config';

// App.js
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const apiClient = generateApiClient({
  baseUrl: Config.API_BASE_URL,
  authorizationHeader: "Authorization",
  authorizationPrefix: "Token ",
  timeout: 60000
});


root.render(
  <ThemeProvider theme={{
    inputField: {
      baseClassName: Theme.inputFieldStyle,
      errorClassName: Theme.inputFieldErrorStyle,
      successClassName: Theme.inputFieldSuccessStyle,
      labelClassName: Theme.inputFieldLabelStyle,
    },
    button: {
      buttonClassName: Theme.buttonClass,
      buttonDisabledClassName: Theme.buttonDisabledClass,
      loader: <div className={Theme.buttonLoader}></div>,
    },
  }}>
    <ApiProvider apiClient={apiClient}>
      <AuthProvider apiClient={apiClient} authUrl="administration/auth/user/">
        <App />
      </AuthProvider>
    </ApiProvider>
  </ThemeProvider>
);

