import accessPermissions from '../Data/accessPermissions';

function dispatcherRole(data, locationPath, navigate) {
  if (!accessPermissions[data.userType].views.includes(locationPath)) {
    navigate('/');
    return false;
  }
}

export default dispatcherRole;
