import React from 'react';

// Styles
import Style from './SlideBox.module.css';

const SlideBox = ({ value, onChange }) => {
  return (
    <label className={Style.checkboxForm}>
      <input type='checkbox' checked={value} onChange={onChange} />
      <i></i>
    </label>
  );
};

export default SlideBox;
