import React, { useEffect, useState } from 'react'

// // Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from "../../Components/Commons/Loader/Loader";
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';

// // Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import dispatcherRole from "../../Utils/dispatcherRole";

// Styles
import Style from "./ReportDetails.module.css";

const historyTypeText = {
    send: "Send",
    sendw: "Withdraw Requested",
    sendwAccepted: "Withdraw Accepted",
    sendwRefused: "Withdraw Refused",
    buyFrom: "Bought from other user",
    buyFor: "Bought for other user",
    buy: "Buy",
    swap: "Swap",
    deposit: "Deposit",
    receive: "From Omega",
};

const ReportDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("id");

    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState(null);
    const [search, setSearch] = useState("");
    const [searchField, setSearchField] = useState("username");
    const [searchLoading, setSearchLoading] = useState(false);
    const [sortedBy, setSortedBy] = useState("username");
    const [sortedType, setSortedType] = useState("asc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredDate, setFilteredDate] = useState([null, null]);
    const [page, setPage] = useState(1);

    const columns = [
        {
            Header: "Transaction ID",
            field: "transactionId",
            accessor: (row) => { return row.transactionId },
        },
        {
            Header: "Type",
            field: "type",
            accessor: (row) => {
                const camelCasedType = row.type.replace(/_([a-z])/g, function (g) {
                    return g[1].toUpperCase();
                });
                return historyTypeText[camelCasedType] || row.type;
            },
        },
        {
            Header: "Amount",
            field: "amount",
            accessor: (row) => { return row.amount },
        },
        {
            Header: "Other User",
            field: "otherUser",
            accessor: (row) => { return row.otherUser },
        },
        {
            Header: "Timestamp",
            field: "timestamp",
            accessor: (row) => { return row.timestamp },
        },
    ];

    const moreInfo = useQuery({
        url: `administration/users/${userId}/history/`,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data)
        },
        onError: (error) => { }
    });

    useEffect(() => {
        setUrl(
            `administration/users/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&limit=10&page=${page}`
        );
    }, [search, sortedBy, sortedType, searchField, filteredDate, page]);

    useEffect(() => {
        document.title = "PULSE WORLD | Report";
        if (url) { moreInfo.executeQuery(); }
        //eslint-disable-next-line
    }, [url])

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            firstApiLoading={
                moreInfo.isLoading
            }
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate)
                return true
            }}
        >
            <section className={Style.userInfo}>
                <Heading viewTitle="Report Details" />

                {tableData && (
                    <GeneralTable
                        data={tableData}
                        columns={columns}
                        setPage={setPage}
                        setSearch={setSearch}
                        searchField={searchField}
                        setSearchField={setSearchField}
                        isLoading={moreInfo.isLoading}
                        sortedBy={sortedBy}
                        setSortedBy={setSortedBy}
                        sortedType={sortedType}
                        setSortedType={setSortedType}
                        fitlerForDate={true}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        filteredDate={filteredDate}
                        setFilteredDate={setFilteredDate}
                        searchLoading={searchLoading}
                        setSearchLoading={setSearchLoading}
                    />
                )}

                {moreInfo.isError && (
                    <div style={{ marginTop: 30 }}>There was a problem generating this table</div>
                )}
            </section>
        </PermissionRoute>
    )
}

export default ReportDetails