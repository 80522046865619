import React, { useState, useEffect } from 'react';

// Librarires
import useQuery from '@hybris-software/use-query';

// Components
import GeneralTable from '../../../../Components/Commons/GeneralTable/GeneralTable';
import Loader from '../../../../Components/Commons/Loader/Loader';

// Styles
// import Style from './History.module.css';

const historyTypeText = {
  send: 'Send',
  sendw: 'Withdraw Requested',
  sendwAccepted: 'Withdraw Accepted',
  sendwRefused: 'Withdraw Refused',
  buyFrom: 'Bought from other user',
  buyFor: 'Bought for other user',
  buy: 'Buy',
  swap: 'Swap',
  deposit: 'Deposit',
  receive: 'From Omega',
};

const History = ({ id }) => {
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState(null);

  const tableApi = useQuery({
    url: url,
    method: 'GET',
    executeImmediately: false,
  });

  useEffect(() => {
    setUrl(`administration/users/${id}/history/?page=${page}`);
  }, [id, page]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  const columns = [
    {
      Header: 'Transaction ID',
      field: 'transactionId',
      accessor: (row) => {
        return row.transactionId;
      },
    },
    {
      Header: 'Type',
      field: 'type',
      accessor: (row) => {
        const camelCasedType = row.type.replace(/_([a-z])/g, function (g) {
          return g[1].toUpperCase();
        });
        return historyTypeText[camelCasedType] || row.type;
      },
    },
    {
      Header: 'Amount',
      field: 'amount',
      accessor: (row) => {
        return row.amount;
      },
    },
    {
      Header: 'Other User',
      field: 'otherUser',
      accessor: (row) => {
        return row.otherUser;
      },
    },
    {
      Header: 'Timestamp',
      field: 'timestamp',
      accessor: (row) => {
        return row.timestamp;
      },
    },
  ];

  return (
    <>
      {tableApi.isLoading ? (
        <Loader />
      ) : tableApi.response ? (
        <GeneralTable
          data={tableApi.response.data}
          columns={columns}
          setPage={setPage}
        />
      ) : (
        <p>An error occurred. Retry later</p>
      )}
    </>
  );
};

export default History;
