import React from 'react'

// Images
import TotalReg from "../../../../Assets/images/totalreg.png";
import TotalDaily from "../../../../Assets/images/totaldaily.png";
import TotalWeekly from "../../../../Assets/images/totalweekly.png";

// Styles
import Style from "./Registered.module.css";

const Registered = ({ usersCount, usersCount24h, usersCount7d }) => {
    return (
        <section>
            <h2 className={Style.label}>Registered Users</h2>
            <div className={Style.registered}>
                <div className={Style.total}>
                    <TotalRegistered totalUsers={usersCount} />
                </div>
                <div className={Style.queried}>
                    <div><DailyRegistered dailyUsers={usersCount24h} /></div>
                    <div><WeeklyRegistered weeklyUseres={usersCount7d} /></div>
                </div>
            </div>
        </section>
    )
}

const TotalRegistered = ({ totalUsers }) => {
    return (
        <div className={Style.totalRegistered}>
            <h6>Total users</h6>
            <div className={Style.stat}>
                <div className={Style.amount}>
                    {totalUsers}
                </div>
                {/* <div className={Style.percentage}>
                    +10%
                </div> */}
            </div>
            <div className={Style.chart}>
                <img src={TotalReg} alt="" />
            </div>
        </div>
    )
}

const DailyRegistered = ({ dailyUsers }) => {
    return (
        <div className={Style.dailyRegistered}>
            <h6>Daily new users</h6>
            <div className={Style.stat}>
                <div className={Style.amount}>
                    {dailyUsers}
                </div>
                {/* <div className={Style.percentage}>
                    +10%
                </div> */}
            </div>
            <div className={Style.chart}>
                <img src={TotalDaily} alt="" />
            </div>
        </div>
    )
}

const WeeklyRegistered = ({ weeklyUseres }) => {
    return (
        <div className={Style.weeklyRegistered}>
            <h6>Weekly new users</h6>
            <div className={Style.stat}>
                <div className={Style.amount}>
                    {weeklyUseres}
                </div>
                {/* <div className={Style.percentage}>
                    +10%
                </div> */}
            </div>
            <div className={Style.chart}>
                <img src={TotalWeekly} alt="" />
            </div>
        </div>
    )
}


export default Registered