import React from 'react'

// Images
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";
import AusdCoin from "../../../../Assets/images/icons/aw-coin.png";

// Styles
import Style from "./PackagesRequest.module.css";

const PackagesRequest = ({ pack }) => {

    const { packetsCount, packetsPusdAmount, packetsAusdAmount } = pack

    return (
        <section>
            <h2 className={Style.label}>Total packages sold</h2>

            <div className={Style.wallet}>

                <div className={Style.card}>
                    <div className={Style.name}>
                        Total packages
                    </div>
                    <div className={Style.stat}>
                        {packetsCount}
                    </div>
                </div>

                <div className={Style.card}>
                    <img src={PusdCoin} alt="" />
                    <div className={Style.name}>
                        Packages PUSD Amount
                    </div>
                    <div className={Style.stat}>
                        {packetsPusdAmount}
                    </div>

                </div>

                <div className={Style.card}>
                    <img src={AusdCoin} alt="" />
                    <div className={Style.name}>
                        Packages AUSD Amount
                    </div>
                    <div className={Style.stat}>
                        {packetsAusdAmount}
                    </div>

                </div>

            </div>

        </section>
    )
}

export default PackagesRequest