import React, { useEffect, useState, useContext } from 'react';

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from '../../Components/Commons/Loader/Loader';
import { Button } from '@hybris-software/ui-kit';

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';
import userHasPermission from "../../Utils/userHasPermission";

// Styles
import Style from './Deposits.module.css';

// Icons
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { GrDocumentCsv } from 'react-icons/gr';

// Data
import { currencies, depositStatus, userActions } from '../../Data/constants';

// Context 
import PermissionsContext from "../../Contexts/PermissionsContext";

const Deposits = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableData, setTableData] = useState(null);
  const [url, setUrl] = useState(null);
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('username');
  const [searchLoading, setSearchLoading] = useState(false);
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortedType, setSortedType] = useState('desc');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredDate, setFilteredDate] = useState([null, null]);
  const [currencyFilter, setCurrencyFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [page, setPage] = useState(1);

  const [permissions] = useContext(PermissionsContext);

  const tableApi = useQuery({
    url: url,
    method: 'GET',
    executeImmediately: false,
    onSuccess: (response) => {
      setTableData(response.data);
      setSearchLoading(false);
    },
    onError: (error) => {
      setSearchLoading(false);
    },
  });

  const exportCsvApi = useQuery({
    url: `administration/deposits/export/?filterfield=${searchField}&filtervalue=${search}&currency=${currencyFilter}&status=${statusFilter}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}`,
    method: 'GET',
    executeImmediately: false,
    clientOptions: {
      timeout: 120000,
    },
    onSuccess: (response) => {
      const blob = new File([response.data], `purchased.csv`, {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
  });

  const columns = [
    {
      Header: 'Order id',
      field: 'id',
      filterfield: 'id',
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: 'Transaction id',
      field: 'url',
      filterfield: 'url',
      accessor: (row) => {
        return row.transactionId;
      },
    },
    {
      Header: 'PUSD amount',
      field: 'amount',
      accessor: (row) =>
        row.amount
          ? `${row.amount} (${row.cryptoAmount} ${currencies[row.currency]})`
          : '',
    },
    {
      Header: 'Username',
      field: 'username',
      filterfield: 'username',
      accessor: (row) => {
        return row.username;
      },
    },
    {
      Header: 'Status',
      field: 'status',
      accessor: (row) => {
        return row.status;
      },
    },
    {
      Header: 'More info',
      accessor: (row) => {
        return (
          <div
            className={Style.info}
            onClick={() => {
              window.open(`/deposit-details?id=${row.id}`, '_blank');
            }}
          >
            <BsFillInfoCircleFill />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setUrl(
      `administration/deposits/?filterfield=${searchField}&filtervalue=${search}&currency=${currencyFilter}&status=${statusFilter}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&limit=10&page=${page}`,
    );
  }, [
    search,
    searchField,
    currencyFilter,
    statusFilter,
    sortedBy,
    sortedType,
    filteredDate,
    page,
  ]);

  useEffect(() => {
    document.title = 'PULSE WORLD | Deposits';
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      firstApiLoading={tableApi.isLoading}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <section>
        <Heading viewTitle={'Deposits'} />

        {tableData && (
          <>
            {userHasPermission(permissions, userActions.export) && (
              <>
                <Button
                  className={Style.export}
                  isLoading={exportCsvApi.isLoading}
                  onClick={() => {
                    exportCsvApi.executeQuery();
                  }}
                >
                  <GrDocumentCsv />
                  Download filtered CSV
                </Button>
                {exportCsvApi.isLoading && (
                  <div className={Style.exportMessage}>
                    Exporting, please wait...
                    <br />
                    The process can take a few seconds.
                  </div>
                )}
                {exportCsvApi.isError && (
                  <div className={Style.exportMessage}>
                    Export failed, try again later.
                  </div>
                )}
                {exportCsvApi.isSuccess && (
                  <div className={Style.exportMessage}>
                    Export success. If the file has not been downloaded check that
                    the browser is not blocking popups.
                  </div>
                )}
              </>
            )}

            <div className={Style.extraFilter}>
              <span>Currency</span>
              <select
                onChange={(e) => setCurrencyFilter(e.target.value)}
                className={Style.selectFilter}
              >
                {Object.keys(currencies).map((currency) => (
                  <option key={currency} value={currency}>
                    {currencies[currency]}
                  </option>
                ))}
              </select>
            </div>
            <div className={Style.extraFilter}>
              <span>Status</span>
              <select
                onChange={(e) => setStatusFilter(e.target.value)}
                className={Style.selectFilter}
              >
                {Object.keys(depositStatus).map((status) => (
                  <option key={status} value={status}>
                    {depositStatus[status]}
                  </option>
                ))}
              </select>
            </div>
            <GeneralTable
              data={tableData}
              columns={columns}
              setPage={setPage}
              setSearch={setSearch}
              searchField={searchField}
              setSearchField={setSearchField}
              isLoading={tableApi.isLoading}
              sortedBy={sortedBy}
              setSortedBy={setSortedBy}
              sortedType={sortedType}
              setSortedType={setSortedType}
              fitlerForDate={true}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              filteredDate={filteredDate}
              setFilteredDate={setFilteredDate}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
            />
          </>
        )}

        {tableApi.isError && (
          <div style={{ marginTop: 30 }}>
            There was a problem generating this table
          </div>
        )}
      </section>
    </PermissionRoute>
  );
};

export default Deposits;
