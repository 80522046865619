import React, { useEffect, useState, useContext } from 'react'

// Components
import Heading from '../../Components/Commons/Heading/Heading'
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from "../../Components/Commons/Loader/Loader";

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from "styled-components";

// Context
import PermissionsContext from '../../Contexts/PermissionsContext';

// Utils
import userHasPermission from '../../Utils/userHasPermission';
import dispatcherRole from '../../Utils/dispatcherRole';

// Icons
import { AiOutlineDownload } from 'react-icons/ai'

// Styles
import Style from "./Export.module.css";

// Data
import { userActions, exportStatus, exportType } from '../../Data/constants';

const CommonStyles = styled.div`
  padding: 1rem;
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tbody tr {
      height: 50px;
      font-size: 13px;
      color: #595959;
      font-weight: 500;
      font-size: 16px;
    }
    th,
    td {
      padding: 20px 0 22px;
      position: relative;
      margin: 0;
      font-weight: 500;
      min-width: 200px;
      max-width: 500px;
      border-bottom: 1px solid #f0f0f0;
      text-align: left;
      :first-child {
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Export = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState(null);
    const [sortedBy, setSortedBy] = useState("createdAt");
    const [sortedType, setSortedType] = useState("desc");
    const [page, setPage] = useState(1);


    const [permissions] = useContext(PermissionsContext);

    const tableApi = useQuery({
        url: url,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data);
        },
    });

    const columns = [
        {
            Header: "Type",
            field: "type",
            accessor: (row) => { return exportType[row.type] },
        },
        {
            Header: "Status",
            field: "status",
            accessor: (row) => (exportStatus[row.status]),
        },
        {
            Header: "Date",
            field: "createdAt",
            accessor: (row) => { return row.createdAt },
        },
    ]
    if (userHasPermission(permissions, userActions.downloadExport)) {
        columns.push(
            {
                Header: "Actions",
                accessor: (row) => {
                    return (

                        <div className={Style.actions}>
                            {row.status === "finished"
                                ?
                                <div
                                    className={Style.approvedButton}
                                    title="Approve"
                                    onClick={() => {
                                        window.open(row.file, '_blank');
                                    }}
                                >
                                    <AiOutlineDownload /> Download
                                </div>
                                :
                                "No actions"
                            }
                        </div>

                    )
                },
            },
        )
    }

    useEffect(() => {
        setUrl(
            `administration/exports/?field=${sortedBy}&sort=${sortedType}&limit=10&page=${page}`
        );
    }, [sortedBy, sortedType, page]);

    useEffect(() => {
        document.title = "PULSE WORLD | Export List";
        if (url) {
            tableApi.executeQuery();
        }
        //eslint-disable-next-line
    }, [url])

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            firstApiLoading={tableApi.isLoading}
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate)
                return true
            }}
        >
            <section>

                <Heading viewTitle={"ExportList"} />

                {tableData && (
                    <GeneralTable
                        data={tableData}
                        columns={columns}
                        setPage={setPage}
                        isLoading={tableApi.isLoading}
                        sortedBy={sortedBy}
                        setSortedBy={setSortedBy}
                        sortedType={sortedType}
                        setSortedType={setSortedType}
                        fitlerForDate={false}
                        Styles={CommonStyles}
                    />
                )}

                {tableApi.isError && (
                    <div style={{ marginTop: 30 }}>There was a problem generating this table</div>
                )}
            </section>
        </PermissionRoute>
    )
}

export default Export