const currencies = {
  all: 'All',
  tusdt: 'tUSDT',
  'usdt-erc20': 'USDT ERC20',
  'usdt-trc20': 'USDT TRC20',
  btc: 'BTC',
  eth: 'ETH',
  usdc: 'USDC',
};

const depositStatus = {
  all: 'All',
  created: 'Pending',
  completed: 'Completed',
  expired: 'Expired',
  cancelled: 'Cancelled',
  partial: 'Partial',
};

const exportStatus = {
  pending: 'Pending',
  finished: 'Finished',
  failed: 'Failed',
};
const exportType = {
  user_list: 'User List',
  user_report: 'User Report',
};

const userRoles = {
  super_admin: 'Super Admin',
  vice_super_admin: 'Vice Super Admin',
  admin: 'Admin',
  support: 'Support',
};

const userActions = {
  viewStats: 'view_stats',
  approveWithdraw: 'approve_withdraw',
  blockUser: 'block_user',
  export: 'export',
  downloadExport: 'download_export',
  approveBalance: 'approve_balance',
  seeBalanceMessage: 'see_balance_message',
};

export { currencies, depositStatus, userRoles, userActions, exportStatus, exportType };
