import React from "react";

// Libraries
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Styles
import Style from "./Chart.module.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const Chart = ({ labels: days, pastPusd }) => {

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  const labels = days;

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Pusd",
        barThickness: "flex",
        barPercentage: 0.2,
        categoryPercentage: 0.4,
        borderRadius: {
          topRight: 8,
          topLeft: 8,
        },
        data: pastPusd,
        backgroundColor: "#623CEA",
      },
    ],
  };

  return (
    <div className={Style.chartContainer}>

      <div className={Style.chart}>
        <Bar
          options={options}
          data={dataChart}
          height="100%"
          style={{
            transition: "width 0.3s",
            height: "100%",
            maxWidth: "100%",
          }}
        />
      </div>
    </div >
  );
};

export default Chart
