import React, { useState } from 'react';

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from '../../Components/Commons/Loader/Loader';
import SlideBox from '../../Components/Commons/SlideBox/SlideBox';
import GeneralPopup from '../../Components/Commons/GeneralPopup/GeneralPopup';

// libraires
import { useNavigate, useLocation } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';
import { InputField, Button, Container } from '@hybris-software/ui-kit';
import useForm from '@hybris-software/use-ful-form';
import useQuery from '@hybris-software/use-query';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';

// Styles
import Style from './CreateAdmin.module.css';

const CreateAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [role, setRole] = useState('support');
  const [showSuccess, setShowSuccess] = useState(false);

  const form = useForm({
    inputs: {
      username: {
        nature: 'username',
        required: true,
      },
      password: {
        nature: 'password',
        required: true,
      },
      email: {
        nature: 'email',
        required: true,
      },
    },
  });

  const createUser = useQuery({
    url: 'administration/admins/',
    method: 'POST',
    executeImmediately: false,
    onError: (error) => {
      if (error.response.status === 422) {
        form.fetchQueryErrors(error.response.data);
      }
    },
    onSuccess: () => {
      setShowSuccess(true);
    },
  });

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <Container>
        {showSuccess && (
          <GeneralPopup>
            <div className={Style.popup}>
              <h4>Success!</h4>
              <p className={Style.popupText}>User created</p>
              <Button onClick={() => setShowSuccess(false)}>Close</Button>
            </div>
          </GeneralPopup>
        )}

        <Heading viewTitle={'Create admin user'} />
        <section className={Style.adminPanel}>
          <div className={Style.line}>
            <div className={Style.label}>Username</div>
            <div className={Style.boxFiled}>
              <InputField
                className={Style.inputUsername}
                type='text'
                placeholder='Enter Username'
                {...form.getInputProps('username')}
              />
            </div>
          </div>

          <div className={Style.line}>
            <div className={Style.label}>Password</div>
            <div className={Style.boxFiled}>
              <InputField
                className={Style.inputPassword}
                type='password'
                placeholder='Enter Password'
                {...form.getInputProps('password')}
              />
            </div>
          </div>

          <div className={Style.line}>
            <div className={Style.label}>Email</div>
            <div className={Style.boxFiled}>
              <InputField
                className={Style.inputEmail}
                type='text'
                placeholder='Enter Email'
                {...form.getInputProps('email')}
              />
            </div>
          </div>

          <div className={Style.role}>
            <div className={Style.label}>Role</div>
            <div className={Style.box}>
              <SlideBox
                value={role === 'vice_super_admin'}
                onChange={() => setRole('vice_super_admin')}
              />
              Vice Super Admin: Work In Progress
            </div>
            <div className={Style.box}>
              <SlideBox
                value={role === 'admin'}
                onChange={() => setRole('admin')}
              />
              Admin: Can view, and modify anything
            </div>
            <div className={Style.box}>
              <SlideBox
                value={role === 'support'}
                onChange={() => setRole('support')}
              />
              Support: Can view anything
            </div>
          </div>

          <Button
            isLoading={createUser.isLoading}
            onClick={() => {
              if (form.validate()) {
                createUser.executeQuery({
                  ...form.getApiBody(),
                  userType: role,
                });
              }
            }}
          >
            Create
          </Button>

          {createUser.error && createUser.error.response.status !== 422 && (
            <div className={Style.error}>
              An error occurred:{' '}
              {JSON.stringify(createUser.error.response.data)}
            </div>
          )}
          {createUser.error &&
            createUser.error.response.status === 422 &&
            Object.keys(createUser.error.response.data)
              .filter((key) => !Object.keys(form.getApiBody()).includes(key))
              .map((key) => (
                <div className={Style.error}>
                  An error occurred: {createUser.error.response.data[key]}
                </div>
              ))}
        </section>
      </Container>
    </PermissionRoute>
  );
};

export default CreateAdmin;
