import React, { useEffect, useState, useContext } from 'react'

// Components
import Heading from '../../Components/Commons/Heading/Heading'
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from "../../Components/Commons/Loader/Loader";

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from "@hybris-software/ui-kit"

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';
import userHasPermission from "../../Utils/userHasPermission";

// Icons
import { GrDocumentCsv } from "react-icons/gr";

// Styles
import Style from "./Purchased.module.css";

// Data
import { userActions } from '../../Data/constants';

// Context 
import PermissionsContext from "../../Contexts/PermissionsContext";

const Purchased = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState(null);
    const [search, setSearch] = useState("");
    const [searchField, setSearchField] = useState("username");
    const [searchLoading, setSearchLoading] = useState(false);
    const [sortedBy, setSortedBy] = useState("createdAt");
    const [sortedType, setSortedType] = useState("asc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredDate, setFilteredDate] = useState([null, null]);
    const [page, setPage] = useState(1);

    const [permissions] = useContext(PermissionsContext);

    const tableApi = useQuery({
        url: url,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data);
            setSearchLoading(false);
        },
        onError: (error) => {
            setSearchLoading(false);
        }
    });

    const exportCsv = useQuery({
        url: `administration/packages/export/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}`,
        method: "GET",
        executeImmediately: false,
        clientOptions: {
            timeout: 120000
        },
        onSuccess: (response) => {
            const blob = new File([response.data], `purchased.csv`, { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        },
    });

    const columns = [
        {
            Header: "Username",
            field: "username",
            filterfield: "username",
            accessor: (row) => { return row.username },
        },
        {
            Header: "Package Name",
            field: "packageName",
            accessor: (row) => { return row.packageName },
        },
        {
            Header: "Date of Purchase",
            field: "createdAt",
            accessor: (row) => { return row.createdAt },
        },
        {
            Header: "Malus",
            field: "penalty",
            accessor: (row) => {
                return row.penalty
                    ? <div style={{ color: "var(--error)" }}>Active</div>
                    : <div style={{ color: "var(--success-secondary)" }}>Inactive</div>
            },
        },
    ];

    useEffect(() => {
        setUrl(
            `administration/packages/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&limit=10&page=${page}`
        );
    }, [search, sortedBy, sortedType, searchField, filteredDate, page]);

    useEffect(() => {
        document.title = "PULSE WORLD | Purchased";
        if (url) { tableApi.executeQuery(); }
        //eslint-disable-next-line
    }, [url])

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            firstApiLoading={tableApi.isLoading}
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate)
                return true
            }}
        >
            <section>
                <Heading viewTitle={"Purchased"} />

                {tableData && (
                    <>
                        {userHasPermission(permissions, userActions.export) && (
                            <>
                                <Button
                                    className={Style.export}
                                    isLoading={exportCsv.isLoading}
                                    onClick={() => {
                                        exportCsv.executeQuery();
                                    }}
                                >
                                    <GrDocumentCsv />
                                    Download filtered CSV
                                </Button>
                                {exportCsv.isLoading && (
                                    <div className={Style.exportMessage}>
                                        Exporting, please wait...
                                        <br />
                                        The process can take a few seconds.
                                    </div>
                                )}
                                {exportCsv.isError && (
                                    <div className={Style.exportMessage}>
                                        Export failed, try again later.
                                    </div>
                                )}
                                {exportCsv.isSuccess && (
                                    <div className={Style.exportMessage}>
                                        Export success. If the file has not been downloaded check
                                        that the browser is not blocking popups.
                                    </div>
                                )}
                            </>
                        )}
                        <GeneralTable
                            data={tableData}
                            columns={columns}
                            setPage={setPage}
                            setSearch={setSearch}
                            searchField={searchField}
                            setSearchField={setSearchField}
                            isLoading={tableApi.isLoading}
                            sortedBy={sortedBy}
                            setSortedBy={setSortedBy}
                            sortedType={sortedType}
                            setSortedType={setSortedType}
                            fitlerForDate={true}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filteredDate={filteredDate}
                            setFilteredDate={setFilteredDate}
                            searchLoading={searchLoading}
                            setSearchLoading={setSearchLoading}
                        />
                    </>
                )}

                {tableApi.isError && (
                    <div style={{ marginTop: 30 }}>There was a problem generating this table</div>
                )}
            </section>
        </PermissionRoute>
    )
}

export default Purchased