import React from 'react'

// Images
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";
import AusdCoin from "../../../../Assets/images/icons/aw-coin.png";

// Styles
import Style from "./Registered.module.css";

const Registered = (props) => {
    return (
        <section>
            <h2 className={Style.label}>Packages type purchaised</h2>
            <div className={Style.registered}>
                {Object.keys(props).map((key, i) => {
                    return (
                        <TotalRegistered key={i} name={key} data={props} />
                    )
                })}
            </div>
        </section>
    )
}

const TotalRegistered = ({ name, data }) => {
    return (
        <div className={Style.totalRegistered}>
            {name &&
                <h6>{name.replace("offer", "Package ")}</h6>
            }
            <div className={Style.stat}>
                <div className={Style.amount}>
                    Total {data[name].total}
                </div>
                <div className={Style.type}>
                    <img src={PusdCoin} alt="" /> {data[name].pusd}
                </div>
                <div className={Style.type}>
                    <img src={AusdCoin} alt="" /> {data[name].ausd}
                </div>
            </div>
        </div>
    )
}

export default Registered