import React, { useState } from 'react'

// Icons
import { IoIosArrowDown } from 'react-icons/io'

// Styles
import Style from "./FilterForTimeWeekly.module.css";

const FilterForTimeWeekly = ({ data, setYear, setWeek, week, year }) => {

    // Cerca il nome in data passando mese ed anno
    const getSelected = (week, year) => {
        const selected = data.find((item) => item.week === week && item.year === year);
        return selected ? selected.startDate + " - " + selected.endDate : null;
    }

    const [openDropdown, setOpenDropdown] = useState(false);
    const [selected, setSelected] = useState(getSelected(week, year) || data[data.length - 1].startDate + " - " + data[data.length - 1].endDate);

    return (
        <div
            className={Style.select}
            onClick={() => {
                setOpenDropdown((oldState) => !oldState)
            }}
            onMouseLeave={() => setOpenDropdown(false)}
        >

            <div className={Style.selected}>
                <span>
                    {selected}
                </span>
                <span className={openDropdown ? Style.arrowOpened : Style.arrow}>
                    <IoIosArrowDown />
                </span>
            </div>
            <div
                style={openDropdown === true ? { maxHeight: "288px" } : { maxHeight: 0 }}
                className={openDropdown === true ? Style.optionsOpened : Style.options}
            >
                {data.map((item, index) => {
                    return (
                        selected !== (item.startDate + " - " + item.endDate) &&
                        <div
                            key={index}
                            onClick={() => {
                                setSelected(item.startDate + " - " + item.endDate);
                                setWeek(item.week);
                                setYear(item.year);
                                console.log(week, year);
                            }}
                        >
                            {item.startDate} - {item.endDate}
                        </div>
                    )
                })}
            </div>
        </div>

    )
}

export default FilterForTimeWeekly