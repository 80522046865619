import React, { useState, useContext } from "react";

// Components
import { Link, useLocation } from "react-router-dom";

// Assets
import LogoDark from "../../../Assets/images/logos/logo-bg-dark.png";
import LogoDarkfull from "../../../Assets/images/logos/logo-black.png";
import { FiChevronDown } from "react-icons/fi";
import { MdSpaceDashboard } from "react-icons/md";
import { RiAdminFill, RiShoppingCartFill } from 'react-icons/ri';
import { HiUsers } from "react-icons/hi";
import { FaFileAlt } from 'react-icons/fa';
import { IoIosWallet, IoIosCard } from 'react-icons/io';

// Hooks
import useMediaQuery from "../../../Hooks/useMediaQuery";

// Context 
import PermissionsContext from "../../../Contexts/PermissionsContext";

// Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./Sidebar.module.css";


const sidebarClass = (sidebarIsOpen, over, isDesktop) => {

  if (isDesktop) {
    // Desktop
    if (sidebarIsOpen) {
      return Style.sidebar;
    } else {
      if (over === true) return Style.sidebar;
      else return Style.sidebarCollapse;
    }
  } else {
    // Mobile
    if (sidebarIsOpen) {
      return Style.sidebarMobile;
    } else {
      return Style.sidebarCollapseMobile;
    }
  }
};

const dropdownLabelClass = (activeFor, location) => {
  if (activeFor.includes(location.pathname)) return Style.elementActive;
  else return Style.element;
};

const linkLabelClass = (isOpen) => {
  if (isOpen) return Style.mainCategoryOpenCollapsed;
  else return Style.mainCategory;
};

const Sidebar = ({ theme, sidebarIsOpen, closeSidebar, setSidebarIsOpen }) => {

  const [permissions] = useContext(PermissionsContext);

  const sidebarConstructor = [
    {
      title: "Dashboard",
      permissions: ["super_admin", "admin", "support"],
      icon: <MdSpaceDashboard className={Style.icon} />,
      link: "/",
      activeFor: ["/"],
    },
    {
      title: "Users",
      permissions: ["super_admin", "admin", "support"],
      icon: <HiUsers className={Style.icon} />,
      activeFor: ["/user-list", "/user-locked"],
      subItems: [
        {
          title: "Users List",
          link: "/user-list",
          activeFor: ["/user-list"],

        },
        {
          title: "Users Locked",
          link: "/user-locked",
          activeFor: ["/user-locked"],
        },
      ],
    },
    {
      title: "Reports",
      permissions: ["super_admin", "admin", "support"],
      icon: <FaFileAlt className={Style.icon} />,
      link: "/report",
      activeFor: ["/report", "/financial-report"],
      subItems: [
        {
          title: "User Report",
          link: "/report",
          activeFor: ["/report"],

        },
        {
          title: "Financial Report",
          link: "/financial-report",
          activeFor: ["/financial-report"],
        },
      ],
    },
    {
      title: "Purchased",
      permissions: ["super_admin", "admin", "support"],
      icon: <RiShoppingCartFill className={Style.icon} />,
      link: "/purchased",
      activeFor: ["/purchased"],
    },
    {
      title: "Admin Features",
      permissions: ["super_admin"],
      icon: <RiAdminFill className={Style.icon} />,
      activeFor: ["/create-admin", "/admin-list", "/platform-popup", "/balance-request", "/export-list"],
      subItems: [
        {
          title: "Create Admin User",
          link: "/create-admin",
          activeFor: ["/create-admin"],
        },
        {
          title: "Admin List",
          link: "/admin-list",
          activeFor: ["/admin-list"],
        },
        {
          title: "Site settings",
          link: "/site-settings",
          activeFor: ["/site-settings"],
        },
        {
          title: "Platform popup",
          link: "/platform-popup",
          activeFor: ["/platform-popup"],
        },
        // {
        //   title: "Balance request",
        //   link: "/balance-request",
        //   activeFor: ["/balance-request"],
        // },
        {
          title: "Export List",
          link: "/export-list",
          activeFor: ["/export-list"],
        },
      ]
    },
    {
      title: "Withdraw",
      permissions: ["super_admin", "admin", "support"],
      icon: <IoIosWallet className={Style.icon} />,
      link: "/withdraw",
      activeFor: ["/withdraw"],
    },
    {
      title: "Deposits",
      permissions: ["super_admin", "admin", "support"],
      icon: <IoIosCard className={Style.icon} />,
      link: "/deposits",
      activeFor: ["/deposits"],
    }
  ];

  const [over, setOver] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState(
    sidebarConstructor.map(() => false)
  );
  const isDesktop = useMediaQuery("(min-width: 991px)");

  const setDropdownOpen = (index, open) => {
    setOpenDropdowns((oldOpenDropdowns) => {
      const newOpenDropdowns = [...oldOpenDropdowns];
      newOpenDropdowns[index] = open;
      return newOpenDropdowns;
    });
  };

  return (
    <div
      className={sidebarClass(sidebarIsOpen, over, isDesktop)}
      onMouseOver={() => {
        if (sidebarIsOpen === false) {
          setOver(true);
          setSidebarIsOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (over) {
          setOver(false);
          setSidebarIsOpen(false);
        }
      }}
    >
      {/* TOP Sidebar */}
      <div className={Style.boxLogo}>
        {isDesktop && (sidebarIsOpen || over) ? (
          <Link to="/">
            <img src={theme === "dark" ? LogoDarkfull : LogoDarkfull} alt="Logo" />
          </Link>
        ) : !isDesktop && (sidebarIsOpen || !sidebarIsOpen) ? (
          <Link to="/">
            <img src={theme === "dark" ? LogoDarkfull : LogoDarkfull} alt="Logo" />
          </Link>
        ) : (
          <Link to="/">
            <img src={LogoDark} alt="" />
          </Link>
        )}
      </div>

      <div className={Style.boxNav}>
        {/* CENTER Sidebar */}
        <div className={Style.navigator}>
          {/* Menu List */}
          <nav>
            <ul>
              {sidebarConstructor.map((el, i) => {
                if (el.permissions.includes(permissions.userType)) {
                  if (el.subItems)
                    return (
                      <NavMenu
                        closeSidebar={closeSidebar}
                        key={i}
                        {...el}
                        isOpen={(sidebarIsOpen || over) && openDropdowns[i]}
                        setOpen={(open) => setDropdownOpen(i, open)}
                      />
                    )
                  else
                    return (
                      <NavSimple closeSidebar={closeSidebar} key={i} {...el} />
                    );
                }
                return null;
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

const NavSimple = ({ title, icon, link, externalLink, activeFor, closeSidebar }) => {
  const location = useLocation();

  return (
    <li className={dropdownLabelClass(activeFor, location)}>
      {externalLink ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <LabelLinkSimple
            closeSidebar={closeSidebar}
            icon={icon}
            title={title}
          />
        </a>) : (
        <Link to={link}>
          <LabelLinkSimple
            closeSidebar={closeSidebar}
            icon={icon}
            title={title}
          />
        </Link>)}
    </li >
  );
};

const LabelLinkSimple = ({ icon, title, closeSidebar }) => {
  return (
    <div onClick={() => closeSidebar()} className={Style.mainCategory}>
      <div className={Style.innerCategory}>
        <div className={Style.boxIcon}>{icon}</div>
        <span className={Style.categoryText}>{title}</span>
      </div>
    </div>
  );
};

const NavMenu = ({
  activeFor,
  subItems,
  icon,
  title,
  isOpen,
  setOpen,
  closeSidebar,
}) => {
  const location = useLocation();

  return (
    <li className={dropdownLabelClass(activeFor, location)}>
      <LabelLink
        icon={icon}
        subItems={subItems}
        title={title}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <Dropdown
        closeSidebar={closeSidebar}
        isOpen={isOpen}
        subItems={subItems}
      />
    </li>
  );
};

const LabelLink = ({
  icon,
  subItems,
  title,
  isOpen,
  setOpen,
  closeSidebar,
}) => {
  return (
    <div
      className={linkLabelClass(isOpen)}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      <div className={Style.innerCategory}>
        <div className={Style.boxIcon}>{icon}</div>
        <span className={Style.categoryText}>{title}</span>
      </div>
      {subItems && (
        <FiChevronDown
          className={classNames(Style.arrow, {
            class: Style.arrowActive,
            condition: isOpen,
          })}
        />
      )}
    </div>
  );
};

const Dropdown = ({ isOpen, subItems, closeSidebar }) => {
  return (
    <ul
      style={{ height: isOpen ? `${subItems.length * 47.6}px` : "0px" }}
      className={classNames(Style.closeDropdown, {
        class: Style.active,
        condition: isOpen,
      })}
    >
      {subItems.map((el, i) => {
        return <DropdownElement closeSidebar={closeSidebar} key={i} {...el} />;
      })}
    </ul>
  );
};

const DropdownElement = ({ link, activeFor, title, closeSidebar }) => {
  const location = useLocation();

  return (
    <li>
      <div className={Style.subCategory}>
        <div className={Style.innerSub}>
          <Link to={link} onClick={() => closeSidebar()}>
            <div
              className={
                activeFor && activeFor.includes(location.pathname) === true
                  ? Style.innerElementActive
                  : Style.innerElement
              }
            >
              {title}
            </div>
          </Link>
        </div>
      </div>
    </li>
  );
};

export default Sidebar;
