import React from 'react'

// Styles
import Style from "./Heading.module.css";

const Heading = ({ viewTitle, viewSubTitle }) => {
    return (
        <section className={Style.header}>
            {viewTitle && <h3 className={Style.title}>{viewTitle}</h3>}
            {viewSubTitle && <p className={Style.subtitle}>{viewSubTitle}</p>}
        </section>
    )
}

export default Heading