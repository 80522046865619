import React from 'react'

// Images
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";
import EthCoin from "../../../../Assets/images/icons/eth-coin.png";
import BtcCoin from "../../../../Assets/images/icons/btc-coin.png";
import UsdtCoin from "../../../../Assets/images/icons/tether-coin.png";
import UsdcCoin from "../../../../Assets/images/icons/usdc-coin.svg";
import MiniChainEth from "../../../../Assets/images/icons/mini-chain-eth.png";
import MiniChainTron from "../../../../Assets/images/icons/mini-chain-tron.png";

// Styles
import Style from "./DepositRequest.module.css";

const DepositRequest = ({ data }) => {

    const { depositBtcAmount, depositBtcCryptoAmount, depositEthAmount, depositEthCryptoAmount, depositUsdcAmount, depositUsdcCryptoAmount, depositUsdterc20Amount, depositUsdterc20CryptoAmount, depositUsdttrc20Amount, depositUsdttrc20CryptoAmount, depositPusdTotal } = data

    return (
        <section>
            <h2 className={Style.label}>Amount of deposits per crypto</h2>

            <div className={Style.wallet}>
                {/* BTC */}
                <div className={Style.card}>
                    <img className={Style.coin} src={BtcCoin} alt="" />
                    <div className={Style.name}>
                        Total amount BTC
                    </div>
                    <div className={Style.stat}>
                        {depositBtcAmount}
                    </div>
                    <div className={Style.crypto}>BTC crypto</div>
                    <div>
                        {depositBtcCryptoAmount}
                    </div>
                </div>

                {/* ETH */}
                <div className={Style.card}>
                    <img className={Style.coin} src={EthCoin} alt="" />
                    <div className={Style.name}>
                        Total amount ETH
                    </div>
                    <div className={Style.stat}>
                        {depositEthAmount}
                    </div>
                    <div className={Style.crypto}>ETH crypto</div>
                    <div>
                        {depositEthCryptoAmount}
                    </div>
                </div>

                {/* USDC */}
                <div className={Style.card}>
                    <img className={Style.coin} src={UsdcCoin} alt="" />
                    <div className={Style.name}>
                        Total amount USDC
                    </div>
                    <div className={Style.stat}>
                        {depositUsdcAmount}
                    </div>
                    <div className={Style.crypto}>USDC crypto</div>
                    <div>
                        {depositUsdcCryptoAmount}
                    </div>
                </div>

                {/* USDTErc20 */}
                <div className={Style.card}>
                    <img className={Style.coin} src={UsdtCoin} alt="" />
                    <img className={Style.chain} src={MiniChainEth} alt="" />
                    <div className={Style.name}>
                        Total amount USDT
                    </div>
                    <div className={Style.stat}>
                        {depositUsdterc20Amount}
                    </div>
                    <div className={Style.crypto}>USDT-erc20 crypto</div>
                    <div>
                        {depositUsdterc20CryptoAmount}
                    </div>
                </div>

                {/* USDT-trc20 */}
                <div className={Style.card}>
                    <img className={Style.coin} src={UsdtCoin} alt="" />
                    <img className={Style.chain} src={MiniChainTron} alt="" />
                    <div className={Style.name}>
                        Total amount USDT
                    </div>
                    <div className={Style.stat}>
                        {depositUsdttrc20Amount}
                    </div>
                    <div className={Style.crypto}>USDT-trc20 crypto</div>
                    <div>
                        {depositUsdttrc20CryptoAmount}
                    </div>
                </div>

                {/* PUSD */}
                <div className={Style.card}>
                    <img className={Style.coin} src={PusdCoin} alt="" />
                    <div className={Style.name}>
                        Total amount PUSD
                    </div>
                    <div className={Style.stat}>
                        {depositPusdTotal}
                    </div>
                </div>
            </div>



        </section>
    )
}

export default DepositRequest