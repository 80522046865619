import React, { useEffect, useState, useContext } from 'react';

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from '../../Components/Commons/Loader/Loader';

// Libraries
import { Button, Container } from '@hybris-software/ui-kit';
import { PermissionRoute } from '@hybris-software/use-auth';
import useQuery from '@hybris-software/use-query';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';
import userHasPermission from '../../Utils/userHasPermission';
import PermissionsContext from '../../Contexts/PermissionsContext';

//Icons
import { IoReload } from 'react-icons/io5';
import { GrDocumentCsv } from 'react-icons/gr';

// Styles
import Style from './FinancialReport.module.css';
import Registered from './Components/Registered/Registered';
import Deposited from './Components/Deposited/Deposited';
import PackagesRequest from './Components/PackagesRequest/PackagesRequest';

// Data
import { userActions } from '../../Data/constants';
import DepositRequest from './Components/DepositRequest/DepositRequest';
import FilterForTime from './Components/FilterForTime/FilterForTime';
import FilterForTimeWeekly from './Components/FilterForTimeWeekly/FilterForTimeWeekly';
import FilterForTimeDay from './Components/FilterForTimeDay/FilterForTimeDay';

const FinancialReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [url, setUrl] = useState(null);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [week, setWeek] = useState(null);
  const [day, setDay] = useState(null);
  const [messageExport, setMessageExport] = useState(false);
  const [permissions] = useContext(PermissionsContext);
  const [filterActive, setFilterActive] = useState('month');

  const exportCsvApi = useQuery({
    url: `administration/stats/financial/export/`,
    method: 'GET',
    executeImmediately: false,
    clientOptions: {
      timeout: 120000,
    },
    onSuccess: (response) => {
      setMessageExport(true);
      const blob = new File([response.data], `finalcial-report.csv`, {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    onError: (error) => {
      setMessageExport(true);
    },
  });

  const exportCsvDailyApi = useQuery({
    url: `administration/stats/financial/export-daily/`,
    method: 'GET',
    executeImmediately: false,
    clientOptions: {
      timeout: 120000,
    },
    onSuccess: (response) => {
      setMessageExport(true);
      const blob = new File([response.data], `finalcial-report-daily.csv`, {
        type: 'text/csv',
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    onError: (error) => {
      setMessageExport(true);
    },
  });

  const detailsApi = useQuery({
    url: url,
    method: 'GET',
    executeImmediately: false,
  });

  const detailsTimeApi = useQuery({
    url: 'administration/stats/months/',
    method: 'GET',
    executeImmediately: true,
  });

  const detailsTimeWeeklyApi = useQuery({
    url: 'administration/stats/weeks/',
    method: 'GET',
    executeImmediately: true,
  });

  useEffect(() => {
    if (userHasPermission(permissions, userActions.viewStats)) {
      console.log('centooooo')
      const searchParameters = new URLSearchParams({
        ...(month && { filtermonth: month }),
        ...(year && { filteryear: year }),
        ...(week && { filterweek: week }),
        ...(day && { filterday: day }),
      }).toString();
      setUrl(`administration/stats/financial/?${searchParameters}`);
    }
    // eslint-disable-next-line
  }, [month, year, week, day]);

  useEffect(() => {
    document.title = 'PULSE WORLD | Financial Report';
    if (url) {
      detailsApi.executeQuery();
    }
    // eslint-disable-next-line
  }, [url]);

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={detailsTimeWeeklyApi.isLoading || detailsTimeApi.isLoading}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <Container>
        <div className={Style.header}>
          <Heading viewTitle={'Financial Report'} />
          {userHasPermission(permissions, userActions.viewStats) && (
            <Button
              className={Style.button}
              disabled={detailsApi.isLoading || isDisabled}
              onClick={() => {
                setIsDisabled(true);
                detailsApi.executeQuery();
                setTimeout(() => {
                  setIsDisabled(false);
                }, 5000);
              }}
            >
              <IoReload /> Reload
            </Button>
          )}
        </div>

        {userHasPermission(permissions, userActions.viewStats) ? (
          <>
            {userHasPermission(permissions, userActions.export) && (
              <>
                <Button
                  className={Style.export}
                  isLoading={exportCsvApi.isLoading}
                  disabled={exportCsvDailyApi.isLoading}
                  onClick={() => {
                    setMessageExport(false);
                    exportCsvApi.executeQuery();
                  }}
                >
                  <GrDocumentCsv />
                  Download monthly report CSV
                </Button>
                <Button
                  className={Style.export}
                  isLoading={exportCsvDailyApi.isLoading}
                  disabled={exportCsvApi.isLoading}
                  onClick={() => {
                    setMessageExport(false);
                    exportCsvDailyApi.executeQuery();
                  }}
                >
                  <GrDocumentCsv />
                  Download daily report CSV
                </Button>
                {(exportCsvApi.isLoading || exportCsvDailyApi.isLoading) && (
                  <div className={Style.exportMessage}>
                    Exporting, please wait...
                    <br />
                    The process can take a few seconds.
                  </div>
                )}
                {messageExport &&
                  (exportCsvApi.isError || exportCsvDailyApi.isError) && (
                    <div className={Style.exportMessage}>
                      Export failed, try again later.
                    </div>
                  )}
                {messageExport &&
                  (exportCsvApi.isSuccess || exportCsvDailyApi.isSuccess) && (
                    <div className={Style.exportMessage}>
                      Export success. If the file has not been downloaded check
                      that the browser is not blocking popups.
                    </div>
                  )}
              </>
            )}

            {detailsTimeApi.response && detailsTimeWeeklyApi.response && (
              <>
                <div className={Style.chooseFilter}>
                  <span
                    className={
                      filterActive === 'month' ? Style.active : undefined
                    }
                    onClick={() => {
                      setFilterActive('month');
                      setYear(detailsTimeApi.response.data[detailsTimeApi.response.data.length - 1].year);
                      setMonth(detailsTimeApi.response.data[detailsTimeApi.response.data.length - 1].month);
                      setWeek(null);
                      setDay(null);
                    }}
                  >
                    Month
                  </span>
                  <span
                    className={
                      filterActive === 'week' ? Style.active : undefined
                    }
                    onClick={() => {
                      setFilterActive('week');
                      setYear(detailsTimeWeeklyApi.response.data[detailsTimeWeeklyApi.response.data.length - 1].year);
                      setMonth(null);
                      setWeek(detailsTimeWeeklyApi.response.data[detailsTimeWeeklyApi.response.data.length - 1].week);
                      setDay(null);
                    }}
                  >
                    Week
                  </span>
                  <span
                    className={
                      filterActive === 'day' ? Style.active : undefined
                    }
                    onClick={() => {
                      setFilterActive('day');
                      setYear(detailsTimeApi.response.data[detailsTimeApi.response.data.length - 1].year);
                      setMonth(detailsTimeApi.response.data[detailsTimeApi.response.data.length - 1].month);
                      setWeek(null);
                      setDay(1);
                    }}
                  >
                    Day
                  </span>
                </div>
                <div
                  className={filterActive === 'day' ? Style.select : undefined}
                >
                  {(filterActive === 'month' || filterActive === 'day') && (
                    <FilterForTime
                      style={
                        filterActive === 'day'
                          ? { minWidth: '194px' }
                          : undefined
                      }
                      data={detailsTimeApi.response.data}
                      setMonth={setMonth}
                      setYear={setYear}
                      setDay={setDay}
                      month={month}
                      year={year}
                    />
                  )}
                  {filterActive === 'week' && (
                    <FilterForTimeWeekly
                      data={detailsTimeWeeklyApi.response.data}
                      setWeek={setWeek}
                      setYear={setYear}
                      week={week}
                      year={year}
                    />
                  )}
                  {filterActive === 'day' && (
                    <FilterForTimeDay
                      setDay={setDay}
                      day={day}
                      month={month}
                      year={year}
                    />
                  )}
                </div>
              </>
            )}
            {detailsApi.response && (
              <>
                <PackagesRequest pack={detailsApi.response.data} />
                <Registered {...detailsApi.response.data.packetsCountOffers} />
                <Deposited chartData={detailsApi.response.data.packagesGraph} />
                <DepositRequest data={detailsApi.response.data} />
              </>
            )}
            {detailsApi.isError && (
              <div style={{ marginTop: 30 }}>
                There was a problem generating this table
              </div>
            )}
          </>
        ) : (
          <div style={{ marginTop: 30 }}>Navigate using the sidebar</div>
        )}
      </Container>
    </PermissionRoute>
  );
};

export default FinancialReport;
