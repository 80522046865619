import React, { useEffect, useState } from 'react';

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from '../../Components/Commons/Loader/Loader';
import GeneralPopup from '../../Components/Commons/GeneralPopup/GeneralPopup';

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { Button } from '@hybris-software/ui-kit';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';

// Data
import { userRoles } from '../../Data/constants';

// Icons
import { MdBlock } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';

// Styles
import Style from './AdminList.module.css';

const AdminList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableData, setTableData] = useState(null);
  const [url, setUrl] = useState(null);
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('username');
  const [searchLoading, setSearchLoading] = useState(false);
  const [sortedBy, setSortedBy] = useState('username');
  const [sortedType, setSortedType] = useState('asc');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredDate, setFilteredDate] = useState([null, null]);
  const [page, setPage] = useState(1);

  const [lockPopup, setLockPopup] = useState(null);
  const [unlockPopup, setUnlockPopup] = useState(null);

  const tableApi = useQuery({
    url: url,
    method: 'GET',
    executeImmediately: false,
    onSuccess: (response) => {
      setTableData(response.data);
      setSearchLoading(false);
    },
    onError: (error) => {
      setSearchLoading(false);
    },
  });

  const showPopup = (id, username, block) => {
    if (block) {
      setLockPopup({
        id,
        username,
      });
    } else {
      setUnlockPopup({
        id,
        username,
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Username',
        field: 'admin_username',
        filterfield: 'admin_username',
        accessor: (row) => {
          return row.username;
        },
      },
      {
        Header: 'Email',
        field: 'email',
        filterfield: 'email',
        accessor: (row) => {
          return row.email;
        },
      },
      {
        Header: 'Role',
        field: 'user_type',
        accessor: (row) => {
          return userRoles[row.userType];
        },
      },
      {
        Header: 'Date of Registration',
        field: 'dateJoined',
        accessor: (row) => {
          return row.dateJoined;
        },
      },
      {
        Header: 'Action',
        field: 'action',
        accessor: (row) => {
          return row.isActive ? (
            <div
              className={Style.blockUser}
              onClick={() => {
                showPopup(row.id, row.username, true);
              }}
            >
              <MdBlock />
              Block
            </div>
          ) : (
            <div
              className={Style.unblockUser}
              onClick={() => {
                showPopup(row.id, row.username, false);
              }}
            >
              <AiOutlineCheckCircle />
              Unblock
            </div>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    setUrl(
      `administration/admins/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&limit=10&page=${page}`,
    );
  }, [search, sortedBy, sortedType, searchField, filteredDate, page]);

  useEffect(() => {
    document.title = 'PULSE WORLD | User List';
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      firstApiLoading={tableApi.isLoading}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <section>
        {lockPopup !== null && (
          <BlockAdminPopup
            userId={lockPopup.id}
            username={lockPopup.username}
            tableApi={tableApi}
            closePopup={() => setLockPopup(null)}
          />
        )}
        {unlockPopup !== null && (
          <UnlockAdminPopup
            userId={unlockPopup.id}
            username={unlockPopup.username}
            tableApi={tableApi}
            closePopup={() => setUnlockPopup(null)}
          />
        )}

        <Heading viewTitle={'User List'} />
        {tableData && (
          <GeneralTable
            data={tableData}
            columns={columns}
            setPage={setPage}
            setSearch={setSearch}
            searchField={searchField}
            setSearchField={setSearchField}
            isLoading={tableApi.isLoading}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
            sortedType={sortedType}
            setSortedType={setSortedType}
            fitlerForDate={true}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            filteredDate={filteredDate}
            setFilteredDate={setFilteredDate}
            searchLoading={searchLoading}
            setSearchLoading={setSearchLoading}
          />
        )}
        {tableApi.isError && (
          <div style={{ marginTop: 30 }}>
            There was a problem generating this table
          </div>
        )}
      </section>
    </PermissionRoute>
  );
};

const BlockAdminPopup = ({ userId, username, tableApi, closePopup }) => {
  const blockAdminApi = useQuery({
    url: `administration/admins/${userId}/block/`,
    method: 'POST',
    executeImmediately: false,
    onSuccess: (response) => {
      tableApi.executeQuery();
      closePopup();
    },
  });

  return (
    <GeneralPopup>
      <div className={Style.popup}>
        <h4>Block staff</h4>
        <p className={Style.popupText}>Do you want to block {username}?</p>
        {blockAdminApi.error && (
          <div className={Style.error}>
            An error occurred:{' '}
            {JSON.stringify(blockAdminApi.error.response.data)}
          </div>
        )}
        <div className={Style.popupButtonGroup}>
          <Button
            isLoading={blockAdminApi.isLoading}
            onClick={() => blockAdminApi.executeQuery()}
          >
            Confirm
          </Button>
          <Button onClick={() => closePopup()}>Close</Button>
        </div>
      </div>
    </GeneralPopup>
  );
};

const UnlockAdminPopup = ({ userId, username, tableApi, closePopup }) => {
  const unlockAdminApi = useQuery({
    url: `administration/admins/${userId}/unlock/`,
    method: 'POST',
    executeImmediately: false,
    onSuccess: (response) => {
      tableApi.executeQuery();
      closePopup();
    },
  });

  return (
    <GeneralPopup>
      <div className={Style.popup}>
        <h4>Unlock staff</h4>
        <p className={Style.popupText}>Do you want to UNLOCK {username}?</p>
        {unlockAdminApi.error && (
          <div className={Style.error}>
            An error occurred:{' '}
            {JSON.stringify(unlockAdminApi.error.response.data)}
          </div>
        )}
        <div className={Style.popupButtonGroup}>
          <Button
            isLoading={unlockAdminApi.isLoading}
            onClick={() => unlockAdminApi.executeQuery()}
          >
            Confirm
          </Button>
          <Button onClick={() => closePopup()}>Close</Button>
        </div>
      </div>
    </GeneralPopup>
  );
};

export default AdminList;
