import React, { useState } from 'react'

//Images
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";
import EthCoin from "../../../../Assets/images/icons/eth-coin.png";
import BtcCoin from "../../../../Assets/images/icons/btc-coin.png";
import UsdtCoin from "../../../../Assets/images/icons/tether-coin.png";
import UsdcCoin from "../../../../Assets/images/icons/usdc-coin.svg";
import MiniChainEth from "../../../../Assets/images/icons/mini-chain-eth.png";
import MiniChainTron from "../../../../Assets/images/icons/mini-chain-tron.png";

// Styles
import Style from "./Deposited.module.css";
import Chart from '../../../../Components/Advanced/Chart/Chart';

const Deposited = ({ deposits, chartData }) => {
    return (
        <section>
            <h2 className={Style.label}>Deposit History</h2>
            <div className={Style.deposited}>
                <TotalDeposited deposits={deposits} />
                <div className={Style.graph}>
                    <h5>Deposit last days</h5>
                    <Chart {...chartData} />
                </div>
            </div>
        </section >
    )
}

const TotalDeposited = ({ deposits }) => {
    const [timeData, setTimeData] = useState("allTime")

    return (
        <div className={Style.total}>
            <div className={Style.head}>
                <div>Total Deposited (+ suspect deposits)</div>
                <div className={Style.filter}>
                    <div
                        className={timeData === "allTime" ? Style.selectedFilter : Style.notSelected}
                        onClick={() => setTimeData("allTime")}
                    >
                        All time
                    </div>
                    <div
                        className={timeData === "last24h" ? Style.selectedFilter : Style.notSelected}
                        onClick={() => setTimeData("last24h")}
                    >
                        Last 24h
                    </div>
                    <div
                        className={timeData === "last7d" ? Style.selectedFilter : Style.notSelected}
                        onClick={() => setTimeData("last7d")}
                    >
                        Last 7 days
                    </div>
                </div>
            </div>

            <div className={Style.singleCurrency}>
                <div className={Style.icon}>
                    <img src={PusdCoin} alt="pusd" />
                </div>
                <div className={Style.stat}>
                    {deposits[timeData]['pusd']['confirmed']} <span className={Style.suspect}>(+ {deposits[timeData]['pusd']['suspect']})</span>
                </div>
            </div>

            <div className={Style.singleCurrency}>
                <div className={Style.icon}>
                    <img src={UsdtCoin} alt="usdterc" />
                    <img className={Style.chain} src={MiniChainEth} alt="usdterc" />
                </div>
                <div className={Style.stat}>
                    {deposits[timeData]['usdterc20']['confirmed']} <span className={Style.suspect}>(+ {deposits[timeData]['usdterc20']['suspect']})</span>
                </div>
            </div>

            <div className={Style.singleCurrency}>
                <div className={Style.icon}>
                    <img src={UsdtCoin} alt="usdttrc" />
                    <img className={Style.chain} src={MiniChainTron} alt="usdterc" />
                </div>
                <div className={Style.stat}>
                    {deposits[timeData]['usdttrc20']['confirmed']} <span className={Style.suspect}>(+ {deposits[timeData]['usdttrc20']['suspect']})</span>
                </div>
            </div>

            <div className={Style.singleCurrency}>
                <div className={Style.icon}>
                    <img src={BtcCoin} alt="btc" />
                </div>
                <div className={Style.stat}>
                    {deposits[timeData]['btc']['confirmed']} <span className={Style.suspect}>(+ {deposits[timeData]['btc']['suspect']})</span>
                </div>
            </div>

            <div className={Style.singleCurrency}>
                <div className={Style.icon}>
                    <img src={EthCoin} alt="eth" />
                </div>
                <div className={Style.stat}>
                    {deposits[timeData]['eth']['confirmed']} <span className={Style.suspect}>(+ {deposits[timeData]['pusd']['suspect']})</span>
                </div>
            </div>

            <div className={Style.singleCurrency}>
                <div className={Style.icon}>
                    <img src={UsdcCoin} alt="usdc" />
                </div>
                <div className={Style.stat}>
                    {deposits[timeData]['usdc']['confirmed']} <span className={Style.suspect}>(+ {deposits[timeData]['pusd']['suspect']})</span>
                </div>
            </div>

        </div>
    )
}

export default Deposited    