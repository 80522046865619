import React, { useState } from 'react'

// Components
import Loader from '../../../Components/Commons/Loader/Loader';

//Libraires
import { Button, InputField } from '@hybris-software/ui-kit';
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { useNavigate } from 'react-router-dom';
import { AuthRoute } from '@hybris-software/use-auth';

//Styles
import Style from "./Login.module.css";

const Login = () => {

    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const form = useForm({
        inputs: {
            username: {
                nature: "username",
                required: true,
            },
            password: {
                nature: "password",
                required: true,
                validator: null,
            }
        }
    })

    const loginApi = useQuery({
        url: "administration/auth/login/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            navigate("/auth/two-factor", {
                state: { loginToken: response.data.loginToken },
            });
        },
        onError: (error) => {
            setError(error.response.data.error)
        },
    });

    return (
        <AuthRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={false}
            action={() => {
                navigate("/");
            }}
        >
            <section className={Style.login}>
                <div className={Style.inner}>
                    <div className={Style.content}>
                        <div className={Style.header}>
                            <div>WELCOME</div>
                            <h1>Login, please enter your details</h1>
                        </div>
                        <InputField
                            className={Style.inputUsername}
                            label={"Username"}
                            type={"text"}
                            placeholder={"Username"}
                            required={true}
                            showError={false}
                            onChange={() => setError(null)}
                            {...form.getInputProps("username")}
                        />

                        <InputField
                            className={Style.inputPassword}
                            label={"Password"}
                            type={"password"}
                            placeholder={"Password"}
                            required={true}
                            showError={false}
                            onChange={() => setError(null)}
                            {...form.getInputProps("password")}
                        />

                        <Button
                            className={Style.buttonLogin}
                            isLoading={loginApi.isLoading}
                            disabled={!form.isValid()}
                            onClick={() => {
                                loginApi.executeQuery(form.getApiBody());
                            }}
                        >
                            Login
                        </Button>
                        {error && <div className={Style.error}>{error}</div>}
                    </div>
                </div>
            </section>
        </AuthRoute>
    )
}

export default Login