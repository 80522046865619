import React, { useState } from 'react';

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from '../../Components/Commons/Loader/Loader';
import SlideBox from '../../Components/Commons/SlideBox/SlideBox';
import GeneralPopup from '../../Components/Commons/GeneralPopup/GeneralPopup';

// libraires
import { useNavigate, useLocation } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';
import { Button, Container } from '@hybris-software/ui-kit';
import useForm from '@hybris-software/use-ful-form';
import useQuery from '@hybris-software/use-query';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';

// Styles
import Style from './SiteSettings.module.css';

const SiteSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [successfulUpdate, setSuccessfulUpdate] = useState(null);

  const siteSettingsApi = useQuery({
    url: 'administration/settings/features/',
    method: 'GET',
    executeImmediately: true,
  });

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <Container>
        <Heading viewTitle={'Site settings'} />
        <section className={Style.adminPanel}>
          {siteSettingsApi.isError && (
            <div style={{ marginTop: 30 }}>
              There was a problem generating this table
            </div>
          )}
          {siteSettingsApi.isLoading && <Loader />}
          {siteSettingsApi.isSuccess && (
            <SiteSettingsForm
              settings={siteSettingsApi.response.data}
              setSuccessfulUpdate={setSuccessfulUpdate}
            />
          )}
        </section>

        {successfulUpdate === true && (
          <GeneralPopup>
            <div className={Style.popup}>
              <h4>Success!</h4>
              <p className={Style.popupText}>Settings updated</p>
              <Button onClick={() => setSuccessfulUpdate(null)}>Close</Button>
            </div>
          </GeneralPopup>
        )}
        {successfulUpdate === false && (
          <GeneralPopup>
            <div className={Style.popup}>
              <h4>Error!</h4>
              <p className={Style.popupText}>Something went wrong, retry later.</p>
              <Button onClick={() => setSuccessfulUpdate(null)}>Close</Button>
            </div>
          </GeneralPopup>
        )}
      </Container>
    </PermissionRoute>
  );
};

const SiteSettingsForm = ({ settings, setSuccessfulUpdate }) => {
  const form = useForm({
    inputs: {
      loginEnabled: {
        nature: 'checkbox',
        value: settings.loginEnabled,
      },
    },
  });

  const updateSiteSettingsApi = useQuery({
    url: 'administration/settings/features/',
    method: 'PATCH',
    executeImmediately: false,
    onSuccess: () => {
      setSuccessfulUpdate(true);
    },
    onError: () => {
      setSuccessfulUpdate(false);
    },
  });

  return (
    <>
      <div className={Style.role}>
        <div className={Style.box}>
          <SlideBox
            value={form.getInputProps('loginEnabled').value}
            onChange={(e) =>
              form.getInputProps('loginEnabled').setValue(e.target.checked)
            }
          />
          User login enabled
        </div>
      </div>

      <Button
        isLoading={updateSiteSettingsApi.isLoading}
        onClick={() => {
          if (form.validate()) {
            updateSiteSettingsApi.executeQuery(form.getApiBody());
          }
        }}
      >
        Update
      </Button>
    </>
  );
};

export default SiteSettings;
