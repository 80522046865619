import { userActions } from './constants';

const accessPermissions = {
  super_admin: {
    views: [
      '/',
      '/user-list',
      '/user-details',
      '/user-locked',
      '/report',
      '/report-details',
      '/financial-report',
      '/purchased',
      '/withdraw',
      '/deposits',
      '/deposit-details',
      '/create-admin',
      '/admin-list',
      '/site-settings',
      '/platform-popup',
      // '/balance-request',
      '/export-list',
    ],
    actions: [
      userActions.viewStats,
      userActions.approveWithdraw,
      userActions.blockUser,
      userActions.export,
      userActions.downloadExport,
      userActions.approveBalance,
      userActions.seeBalanceMessage,
    ],
  },
  vice_super_admin: {
    views: ['/'],
    actions: [],
  },
  admin: {
    views: [
      '/',
      '/user-list',
      '/user-details',
      '/user-locked',
      '/report',
      '/report-details',
      '/purchased',
      '/withdraw',
      '/deposits',
      '/deposit-details',
    ],
    actions: [userActions.approveWithdraw],
  },
  support: {
    views: [
      '/',
      '/user-list',
      '/user-details',
      '/user-locked',
      '/report',
      '/report-details',
      '/purchased',
      '/withdraw',
      '/deposits',
      '/deposit-details',
    ],
    actions: [],
  },
};

export default accessPermissions;
