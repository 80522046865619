import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from '../../Components/Commons/Loader/Loader';

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';

// Styles
import Style from './DepositDetails.module.css';

// Constants
import { currencies } from '../../Data/constants';

const DepositDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const moreInfo = useQuery({
    url: `administration/deposits/${searchParams.get('id')}/`,
    method: 'GET',
    executeImmediately: true,
  });

  useEffect(() => {
    document.title = 'PULSE WORLD | Deposit details';
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      firstApiLoading={moreInfo.isLoading}
      unAuthorizedAction={() => {
        navigate('/auth/login');
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <section className={Style.userInfo}>
        <Heading viewTitle='Deposit details' />
        {moreInfo.response && <PulseData data={moreInfo.response.data.pulse} />}
        {moreInfo.response && (
          <LyomerchantData data={moreInfo.response.data.lyomerchant} />
        )}
      </section>
    </PermissionRoute>
  );
};

const PulseData = ({ data }) => {
  return (
    <div className={Style.infoGroup}>
      <h4>Data on Pulse</h4>
      <div className={Style.information}>
        <div>
          <strong>Id:</strong> {data.id}
        </div>
        <div>
          <strong>Transaction id:</strong> {data.transactionId}
        </div>
        <div>
          <strong>Amount:</strong> {data.amount} PUSD
        </div>
        <div>
          <strong>Crypto amount:</strong> {data.cryptoAmount}
        </div>
        <div>
          <strong>Request date:</strong> {data.createdAt}
        </div>
        <div>
          <strong>Currency:</strong> {currencies[data.currency]}
        </div>
        <div>
          <strong>Requested crypt amount:</strong> {data.requestedCryptoAmount}
        </div>
        <div>
          <strong>Status:</strong> {data.status}
        </div>
        <div>
          <strong>Destination wallet:</strong> {data.walletAddress}
        </div>
      </div>
    </div>
  );
};

const LyomerchantData = ({ data }) => {
  return (
    <div className={Style.infoGroup}>
      <h4>Data on Lyomerchant</h4>
      <div className={Style.information}>
        {Object.keys(data).map((key) => (
          <div>
            <strong>{key}:</strong> {data[key]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DepositDetails;
