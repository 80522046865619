import React, { useEffect, useState, useContext } from 'react'

// Components
import Heading from '../../Components/Commons/Heading/Heading'
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from "../../Components/Commons/Loader/Loader";

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';

// Context
import PermissionsContext from '../../Contexts/PermissionsContext';

// Utils
import userHasPermission from '../../Utils/userHasPermission';
import dispatcherRole from '../../Utils/dispatcherRole';

// Icons
import { MdBlock } from 'react-icons/md'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { BsFillInfoCircleFill } from 'react-icons/bs'

// Data
import { userActions } from '../../Data/constants';

// Styles
import Style from "./UserBlocked.module.css";

const UserBlocked = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState(null);
    const [search, setSearch] = useState("");
    const [searchField, setSearchField] = useState("username");
    const [searchLoading, setSearchLoading] = useState(false);
    const [sortedBy, setSortedBy] = useState("username");
    const [sortedType, setSortedType] = useState("asc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredDate, setFilteredDate] = useState([null, null]);
    const [page, setPage] = useState(1);

    const [permissions] = useContext(PermissionsContext);

    const tableApi = useQuery({
        url: url,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data);
            setSearchLoading(false);
        },
        onError: (error) => {
            setSearchLoading(false);
        }
    });

    const blockUser = useQuery({
        url: `administration/users/block/`,
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            tableApi.executeQuery();
        }
    });

    const columns = React.useMemo(
        () => {
            const cols = [
            {
                Header: "Username",
                field: "username",
                filterfield: "username",
                accessor: (row) => { return row.username },
            },
            {
                Header: "OTP",
                field: "otp",
                accessor: (row) => { return "Not Available" },
            },
            {
                Header: "Date of Registration",
                field: "dateJoined",
                accessor: (row) => { return row.dateJoined },
            },
            ];
            if (userHasPermission(permissions, userActions.blockUser)) {
            cols.push({
                Header: "Action",
                field: "action",
                accessor: (row) => {
                    return row.isActive
                        ?
                        <div className={Style.blockUser}
                            onClick={() => {
                                blockUser.executeQuery({
                                    id: row.id,
                                    block: true,
                                });
                            }}
                        ><MdBlock />Block</div>
                        :
                        <div className={Style.unblockUser}
                            onClick={() => {
                                blockUser.executeQuery({
                                    id: row.id,
                                    block: false,
                                });
                            }}
                        ><AiOutlineCheckCircle />Unblock</div>
                },
            });
            }
            cols.push({
                Header: "More info",
                accessor: (row) => {
                    return (
                        <div
                            className={Style.info}
                            onClick={() => {
                                window.open(`/user-details?id=${row.id}`, "_blank");
                            }}
                        >
                            <BsFillInfoCircleFill />
                        </div>
                    )
                },
            });
            return cols;
        },
        [blockUser]
    );


    useEffect(() => {
        setUrl(
            `administration/users/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&blocked=true&page=${page}`
        );
    }, [search, sortedBy, sortedType, searchField, filteredDate, page]);

    useEffect(() => {
        document.title = "PULSE WORLD | User Blocked";
        if (url) { tableApi.executeQuery(); }
        //eslint-disable-next-line
    }, [url])

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            firstApiLoading={tableApi.isLoading}
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate)
                return true
            }}
        >
            <section>
                <Heading viewTitle={"User Blocked"} />
                {tableData && (
                    <GeneralTable
                        data={tableData}
                        columns={columns}
                        setPage={setPage}
                        setSearch={setSearch}
                        searchField={searchField}
                        setSearchField={setSearchField}
                        isLoading={tableApi.isLoading}
                        sortedBy={sortedBy}
                        setSortedBy={setSortedBy}
                        sortedType={sortedType}
                        setSortedType={setSortedType}
                        fitlerForDate={true}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        filteredDate={filteredDate}
                        setFilteredDate={setFilteredDate}
                        searchLoading={searchLoading}
                        setSearchLoading={setSearchLoading}
                    />
                )}
                {tableApi.isError && (
                    <div style={{ marginTop: 30 }}>There was a problem generating this table</div>
                )}
            </section>
        </PermissionRoute>
    )
}

export default UserBlocked