import React, { useState, useEffect } from 'react';

// Librarires
import useQuery from '@hybris-software/use-query';

// Components
import GeneralTable from '../../../../Components/Commons/GeneralTable/GeneralTable';
import Loader from '../../../../Components/Commons/Loader/Loader';

// Styles
// import Style from './Packages.module.css';

const Packages = ({ id }) => {
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState(null);

  const tableApi = useQuery({
    url: url,
    method: 'GET',
    executeImmediately: false,
  });

  useEffect(() => {
    setUrl(`administration/users/${id}/packages/?page=${page}`);
  }, [id, page]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  const columns = [
    {
      Header: 'Username',
      field: 'username',
      accessor: (row) => {
        return row.username;
      },
    },
    {
      Header: 'Package Name',
      field: 'packageName',
      accessor: (row) => {
        return row.packageName;
      },
    },
    {
      Header: 'Date of Purchase',
      field: 'createdAt',
      accessor: (row) => {
        return row.createdAt;
      },
    },
    {
      Header: 'Malus',
      field: 'penalty',
      accessor: (row) => {
        return row.penalty ? (
          <div style={{ color: 'var(--error)' }}>Active</div>
        ) : (
          <div style={{ color: 'var(--success-secondary)' }}>Inactive</div>
        );
      },
    },
  ];

  return (
    <>
      {tableApi.isLoading ? (
        <Loader />
      ) : tableApi.response ? (
        <GeneralTable
          data={tableApi.response.data}
          columns={columns}
          setPage={setPage}
        />
      ) : (
        <p>An error occurred. Retry later</p>
      )}
    </>
  );
};

export default Packages;
