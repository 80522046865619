import React from "react";

// Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./GeneralPopup.module.css";


const GeneralPopup = ({ children, style, className }) => {
  return (
    <div className={Style.popup}>
      <div className={classNames(Style.centeredElement, className)} style={style}>
        {children}
      </div>
    </div>
  );
};

export default GeneralPopup
