import React, { useState, useContext } from "react";

// Components
import LoaderGlobal from "../../Components/Commons/LoaderGlobal/LoaderGlobal";
import Navbar from "../../Components/Commons/Navbar/Navbar";
import Sidebar from "../../Components/Commons/Sidebar/Sidebar";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate, Outlet } from "react-router-dom";

// Utils
import SidebarContext from "../../Contexts/sidebarContext";
import PermissionsContext from "../../Contexts/PermissionsContext";

// Styles
import Style from "./MainLayout.module.css";

// Data
// import Config from "../../Data/config";

const MainLayout = () => {

  const navigate = useNavigate();
  const [theme, setTheme] = useState("light");
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const [permissions, setPermissions] = useContext(PermissionsContext);

  const closeSidebar = () => {
    if (window.innerWidth < 991 && sidebarIsOpen) {
      setSidebarIsOpen(false);
    }
  };

  return (
    <PermissionRoute
      loader={<LoaderGlobal />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        setPermissions({
          username: response.data.username,
          userType: response.data.userType,
        })
        return true
      }}
    >
      <SidebarContext.Provider value={sidebarIsOpen}>
        <div className={Style.layouting} data-theme={theme}>
          <Sidebar
            theme={theme}
            setTheme={setTheme}
            closeSidebar={closeSidebar}
            sidebarIsOpen={sidebarIsOpen}
            setSidebarIsOpen={setSidebarIsOpen}
          />
          <div className={sidebarIsOpen ? Style.mainContainer : Style.mainContainerCollapse} onClick={() => closeSidebar()}>
            <Navbar
              sidebarIsOpen={sidebarIsOpen}
              setSidebarIsOpen={setSidebarIsOpen}
            />
            <main className={Style.contentContainer}>
              <Outlet />
            </main>
          </div>
        </div>
      </SidebarContext.Provider>
    </PermissionRoute>
  );
};

export default MainLayout;
