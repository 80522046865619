import React from "react";

//Components
import LoaderDefaultImage from "../LoaderDefaultImage/LoaderDefaultImage";

//Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./LoaderGlobal.module.css";

const LoaderGlobal = ({
  className,
  children,
  style,
  classNameImageContainer,
}) => {
  return (
    <div style={style} className={classNames(Style.globalLoader, className)}>
      <div
        className={classNames(
          Style.defaultLoaderImage,
          classNameImageContainer
        )}
      >
        {!children ? <LoaderDefaultImage /> : children}
      </div>
    </div>
  );
};

export default LoaderGlobal;
