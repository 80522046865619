import React from "react";

//Libraries
import useForm from "@hybris-software/use-ful-form";
import { Button, InputField } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Icons
import { BiEditAlt } from "react-icons/bi";

// Styles
import Style from "./MoreInformation.module.css";
import { useState } from "react";

const MoreInformation = ({
  username,
  postCode,
  city,
  countryCode,
  email,
  phone,
  fullName,
  walletAddress,
  userId,
  moreInfo,
}) => {
  const [showedMail, setShowedMail] = useState(false);
  const [emailState, setEmailState] = useState(email);
  const [error, setError] = useState({
    errorCall: null,
    successCall: null,
  });

  const emailForm = useForm({
    inputs: {
      email: {
        nature: "email",
        required: true,
        value: email,
      },
    },
  });

  const changeEmail = useQuery({
    url: `administration/users/${userId}/change-email/`,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setEmailState(emailForm.getInputProps("email").value);
      setShowedMail(false);
      setError({
        ...error,
        errorCall: null,
        successCall: "Email changed successfully",
      });
      setTimeout(() => {
        setError({ ...error, errorCall: null, successCall: null });}, 2000);
    },
    onError: (error) => {
      if (error.response.status === 422) {
        emailForm.fetchQueryErrors(error.response.data);
      } else {
        setError({ ...error, errorCall: error.response.data.error });
      }
    },
  });

  return (
    <div className={Style.popup}>
      {username && (
        <div>
          <strong>Username: </strong> {username}
        </div>
      )}
      {fullName && (
        <div>
          <strong>Full name: </strong> {fullName}
        </div>
      )}
      {email && (
        <div>
          <strong>Email: </strong> {emailState}
          <BiEditAlt
            onClick={() => setShowedMail(!showedMail)}
            className={Style.editIcon}
          ></BiEditAlt>
          {error.successCall && (
            <span className={Style.successCall}>{error.successCall}</span>
          )}
          <div
            style={{
              maxHeight: showedMail ? "200px" : "0px",
              overflow: showedMail ? "visible" : "hidden",
              opacity: showedMail ? "100" : "0",
              transition: "all .5s ease-out",
              marginBottom: "10px",
            }}
          >
            <div className={Style.updateEmailForm}>
              <InputField
                style={{width: "40vw", minWidth: "250px"}}
                label="Email *"
                type="email"
                placeholder="Email"
                {...emailForm.getInputProps("email")}
              />
              <div>
                <Button
                  onClick={() =>
                    changeEmail.executeQuery({ ...emailForm.getApiBody() })
                  }
                  disabled={!emailForm.isValid()}
                  isLoading={changeEmail.isLoading}
                >
                  Submit
                </Button>
              </div>
            </div>
            {error.errorCall && (
              <div className={Style.errorCall}>{error.errorCall}</div>
            )}
          </div>
        </div>
      )}
      {phone && (
        <div>
          <strong>Phone: </strong> {phone}
        </div>
      )}
      {city && (
        <div>
          <strong>City: </strong> {city}
        </div>
      )}
      {countryCode && (
        <div>
          <strong>Contry: </strong> {countryCode}
        </div>
      )}
      {postCode && (
        <div>
          <strong>Zip code: </strong> {postCode}
        </div>
      )}
      {walletAddress && (
        <div>
          <strong>Wallet address: </strong> {walletAddress}
        </div>
      )}

      {username === undefined && (
        <div className={Style.noData}>Data could not be recovered</div>
      )}
    </div>
  );
};

export default MoreInformation;
