import React, { useState } from 'react';

// Librarires
import useQuery from '@hybris-software/use-query';

// Components
import GeneralTable from '../../../../Components/Commons/GeneralTable/GeneralTable';
import Loader from '../../../../Components/Commons/Loader/Loader';

// Data
import { currencies } from '../../../../Data/constants';

// Icons
import { BsFillInfoCircleFill } from 'react-icons/bs';

// Styles
import Style from './Deposits.module.css';
import { useEffect } from 'react';

const Deposits = ({ id }) => {
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState(null);

  const tableApi = useQuery({
    url: url,
    method: 'GET',
    executeImmediately: false,
  });

  useEffect(() => {
    setUrl(`administration/users/${id}/deposits/?page=${page}`);
  }, [id, page]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  const columns = [
    {
      Header: 'Order id',
      field: 'id',
      accessor: (row) => {
        return row.id;
      },
    },
    {
      Header: 'Transaction id',
      field: 'url',
      accessor: (row) => {
        return row.transactionId;
      },
    },
    {
      Header: 'PUSD amount',
      field: 'amount',
      accessor: (row) =>
        row.amount
          ? `${row.amount} (${row.cryptoAmount} ${currencies[row.currency]})`
          : '',
    },
    {
      Header: 'Status',
      field: 'status',
      accessor: (row) => {
        return row.status;
      },
    },
    {
      Header: 'Date',
      field: 'createdAt',
      accessor: (row) => {
        return row.createdAt;
      },
    },
    {
      Header: 'More info',
      accessor: (row) => {
        return (
          <div
            className={Style.info}
            onClick={() => {
              window.open(`/deposit-details?id=${row.id}`, '_blank');
            }}
          >
            <BsFillInfoCircleFill />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {tableApi.isLoading && <Loader />}
      {tableApi.response ? (
        <GeneralTable
          data={tableApi.response.data}
          columns={columns}
          setPage={setPage}
        />
      ) : (
        <p>An error occurred. Retry later</p>
      )}
    </>
  );
};

export default Deposits;
