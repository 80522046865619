import React from 'react'
import ChartFinancial from '../../../../Components/Advanced/ChartFinancial/ChartFinancial';

// Styles
import Style from "./Deposited.module.css";

const Deposited = ({ chartData }) => {

    return (
        <section>
            <div className={Style.deposited}>
                <div className={Style.graph}>
                    <h5 style={{ marginBottom: 20 }}>Packets bought per day</h5>
                    <ChartFinancial {...chartData} />
                </div>
            </div>
        </section >
    )
}


export default Deposited    