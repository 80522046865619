import React, { useEffect } from 'react'

// Components
import LoaderGlobal from '../../../Components/Commons/LoaderGlobal/LoaderGlobal';

// Libraires
import { Button } from '@hybris-software/ui-kit';
import { useNavigate } from 'react-router-dom';
import useQuery from '@hybris-software/use-query';

//Images
import LogoutIcon from "../../../Assets/images/logout.png";

// Sytles
import Style from "./Logout.module.css";

const Logout = () => {

    const navigate = useNavigate();
    const logoutApi = useQuery({
        url: "administration/auth/logout/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            localStorage.removeItem("token");
        }
    });

    useEffect(() => {
        document.title = "PULSE WORLD | Logout";
        logoutApi.executeQuery();
        //eslint-disable-next-line
    }, [])

    return (
        <>
            {
                logoutApi.isLoading ? <LoaderGlobal />
                    :
                    <section className={Style.logout}>
                        <div className={Style.inner}>
                            <div className={Style.content}>
                                <div className={Style.header}>
                                    <img src={LogoutIcon} alt="" />
                                    <div>See you Again</div>
                                    <h1>You are now successfully signed out.</h1>
                                </div>

                                <Button
                                    className={Style.buttonLogin}
                                    isLoading={false}
                                    onClick={() => {
                                        navigate("/auth/login");
                                    }}
                                >
                                    Login again?
                                </Button>
                            </div>
                        </div>
                    </section>
            }
        </>
    )
}

export default Logout