import React, { useEffect, useState, useContext } from "react";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import Loader from "../../Components/Commons/Loader/Loader";

// Libraries
import { Button, Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import dispatcherRole from "../../Utils/dispatcherRole";
import userHasPermission from "../../Utils/userHasPermission";
import PermissionsContext from "../../Contexts/PermissionsContext";

//Icons 
import { IoReload } from 'react-icons/io5'

// Styles
import Style from "./Dashboard.module.css";
import Registered from "./Components/Registered/Registered";
import Deposited from "./Components/Deposited/Deposited";
import WalletRequest from "./Components/WalletRequest/WalletRequest";

// Data
import { userActions } from "../../Data/constants";

const DashBoard = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);

  const [permissions, setPermissions] = useContext(PermissionsContext);

  const detailsApi = useQuery({
    url: "administration/stats/",
    method: "GET",
    executeImmediately: false,
  })


  useEffect(() => {
    document.title = "PULSE WORLD | Dashboard";
    if (userHasPermission(permissions, userActions.viewStats)) {
      detailsApi.executeQuery();
    }
  }, []);

  return (
    <PermissionRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={detailsApi.isLoading}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcherRole(response.data, location.pathname, navigate)
        return true
      }}
    >
      <Container>
        <div className={Style.header}>
          <Heading viewTitle={"Dashboard"} />
          {userHasPermission(permissions, userActions.viewStats) && (
            <Button
              className={Style.button}
              disabled={detailsApi.isLoading || isDisabled}
              onClick={() => {
                setIsDisabled(true);
                detailsApi.executeQuery();
                setTimeout(() => {
                  setIsDisabled(false);
                }, 5000);
              }}
            >
              <IoReload /> Reload
            </Button>
          )}
        </div>

        {userHasPermission(permissions, userActions.viewStats) ? (
          <>
            {detailsApi.response && (
              <>
                <Registered {...detailsApi.response.data} />
                <Deposited
                  deposits={detailsApi.response.data.deposits}
                  chartData={detailsApi.response.data.depositGraph}
                />
                <WalletRequest withdrawData={detailsApi.response.data} />
              </>
            )}
            {detailsApi.isError && (
              <div style={{ marginTop: 30 }}>
                There was a problem generating this table
              </div>
            )}
          </>
        ) : (
          <div style={{ marginTop: 30 }}>
            Navigate using the sidebar
          </div>
        )}
      </Container>
    </PermissionRoute>
  );
};

export default DashBoard;
