import React, { useState, useEffect } from "react";

// Components
import Loader from "../../../Components/Commons/Loader/Loader";

// Libraries
import AuthCode from "react-auth-code-input";
import { Button } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";

// Styles
import Style from "./TwoFactor.module.css";

const TwoFactor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [authCode, setAuthCode] = useState("");
    const [errorCall, setErrorCall] = useState(null);
    const [successCall, setSuccessCall] = useState(null);

    const codeVerification = useQuery({
        url: "administration/auth/login/confirm/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            localStorage.setItem("token", response.data.token);
            navigate("/");
        },
        onError: (error) => {
            setErrorCall(error.response.data?.message || "Something went wrong");
        },
    });

    useEffect(() => {
        document.title = "PULSE WORLD | 2-Step Verification";
    }, []);

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={false}
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={() => {
                if (!location.state?.loginToken) {
                    navigate("/");
                    return;
                }
                return true;
            }}
        >
            <section className={Style.login}>
                <div className={Style.inner}>
                    <div className={Style.content}>

                        <div className={Style.heading}>
                            <h4>2-Step verification</h4>
                            <p>
                                We sent a verification code to your email.
                                <br /> Enter the code from the email in the field below.
                            </p>
                        </div>

                        <div className={Style.form}>
                            <AuthCode
                                allowedCharacters="numeric"
                                onChange={(e) => {
                                    setAuthCode(e);
                                    setErrorCall(null);
                                    setSuccessCall(null);
                                }}
                            />
                            {errorCall && <div className={Style.errorCall}>{errorCall}</div>}
                        </div>

                        <Button
                            disabled={authCode.length < 6 || codeVerification.isLoading}
                            isLoading={codeVerification.isLoading}
                            onClick={() => {
                                codeVerification.executeQuery({
                                    loginToken: location.state?.loginToken,
                                    otp: authCode,
                                });
                            }}
                        >
                            Verify my account
                        </Button>
                        <Button
                            className={Style.return}
                            onClick={() => {
                                navigate("/auth/login")
                            }}
                        >
                            Return to login
                        </Button>

                        {successCall && <div className={Style.successCall}>{successCall}</div>}
                    </div>
                </div>
            </section>
        </PermissionRoute>
    );
};

export default TwoFactor
