import React, { useEffect, useState, useContext } from 'react'

// Components
import Heading from '../../Components/Commons/Heading/Heading'
import GeneralTable from '../../Components/Commons/GeneralTable/GeneralTable';
import Loader from "../../Components/Commons/Loader/Loader";

// Librarires
import useQuery from '@hybris-software/use-query';
import { PermissionRoute } from '@hybris-software/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@hybris-software/ui-kit';

// Context
import PermissionsContext from '../../Contexts/PermissionsContext';

// Utils
import userHasPermission from '../../Utils/userHasPermission';
import dispatcherRole from '../../Utils/dispatcherRole';

// Icons
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { GrDocumentCsv } from 'react-icons/gr';

// Data
import { userActions } from '../../Data/constants';

// Styles
import Style from "./Report.module.css";

const Report = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState(null);
    const [search, setSearch] = useState("");
    const [searchField, setSearchField] = useState("username");
    const [searchLoading, setSearchLoading] = useState(false);
    const [sortedBy, setSortedBy] = useState("username");
    const [sortedType, setSortedType] = useState("asc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredDate, setFilteredDate] = useState([null, null]);
    const [page, setPage] = useState(1);
    const [messageExport, setMessageExport] = useState("");

    const [permissions] = useContext(PermissionsContext);

    const tableApi = useQuery({
        url: url,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data);
            setSearchLoading(false);
        },
        onError: (error) => {
            setSearchLoading(false);
        }
    });

    const exportCsvApi = useQuery({
        url: `administration/users/export/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}`,
        method: 'GET',
        executeImmediately: false,
        clientOptions: {
            timeout: 120000,
        },
        onSuccess: (response) => {
            if (response.status === 200) setMessageExport(response.data.message + "... Check status in the export list")
        },
        onError: (error) => {
            if (error.response.status === 400) setMessageExport(error.response.data.message + ". Check status in the export list")
        }
    });


    const columns = [
        {
            Header: "Username",
            field: "username",
            filterfield: "username",
            accessor: (row) => { return row.username },
        },
        {
            Header: "Balance PUSD",
            field: "pusdBalance",
            accessor: (row) => { return row.pusdBalance },
        },
        {
            Header: "PUSD Deposited",
            field: "pusdDeposited",
            accessor: (row) => { return row.pusdDeposited },
        },
        {
            Header: "Balance Spent",
            field: "pusdSpent",
            accessor: (row) => { return row.pusdSpent },
        },
        {
            Header: "Balance Sent",
            field: "pusdSent",
            accessor: (row) => { return row.pusdSent },
        },
        {
            Header: "Action",
            field: "action",
            accessor: (row) => {
                return (
                    <div
                        className={Style.details}
                        onClick={() => {
                            window.open(`/report-details?id=${row.id}`, "_blank");

                        }}
                    >
                        <MdOutlineRemoveRedEye />View history
                    </div>
                )
            },
        },
    ];

    useEffect(() => {
        setUrl(
            `administration/users/?filterfield=${searchField}&filtervalue=${search}&field=${sortedBy}&sort=${sortedType}&startdate=${filteredDate[0]}&enddate=${filteredDate[1]}&limit=10&page=${page}`
        );
    }, [search, sortedBy, sortedType, searchField, filteredDate, page]);

    useEffect(() => {
        document.title = "PULSE WORLD | Report";
        if (url) { tableApi.executeQuery(); }
        //eslint-disable-next-line
    }, [url])

    return (
        <PermissionRoute
            loader={<Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            firstApiLoading={tableApi.isLoading}
            unAuthorizedAction={() => {
                navigate("/auth/login");
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate)
                return true
            }}
        >
            <section>
                <Heading viewTitle={"Report"} />

                {userHasPermission(permissions, userActions.export) && (
                    <>
                        <Button
                            className={Style.export}
                            isLoading={exportCsvApi.isLoading}
                            onClick={() => {
                                exportCsvApi.executeQuery();
                            }}
                        >
                            <GrDocumentCsv />
                            Download filtered CSV
                        </Button>
                        <div className={Style.exportMessage}>
                            {messageExport}
                        </div>
                    </>
                )}

                {tableData && (
                    <GeneralTable
                        data={tableData}
                        columns={columns}
                        setPage={setPage}
                        setSearch={setSearch}
                        searchField={searchField}
                        setSearchField={setSearchField}
                        isLoading={tableApi.isLoading}
                        sortedBy={sortedBy}
                        setSortedBy={setSortedBy}
                        sortedType={sortedType}
                        setSortedType={setSortedType}
                        fitlerForDate={true}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        filteredDate={filteredDate}
                        setFilteredDate={setFilteredDate}
                        searchLoading={searchLoading}
                        setSearchLoading={setSearchLoading}
                        extraFilters={[
                            {
                                key: 'email',
                                name: 'Email',
                            },
                        ]}
                    />
                )}
                {tableApi.isError && (
                    <div style={{ marginTop: 30 }}>There was a problem generating this table</div>
                )}
            </section>
        </PermissionRoute>
    )
}

export default Report