import React, { useState } from 'react'

// Components
import Heading from '../../Components/Commons/Heading/Heading';
import Loader from '../../Components/Commons/Loader/Loader';
import GeneralPopup from '../../Components/Commons/GeneralPopup/GeneralPopup';

// Libraires
import { useNavigate, useLocation } from 'react-router-dom';
import { PermissionRoute } from '@hybris-software/use-auth';
import { InputField, Button, Container } from '@hybris-software/ui-kit';
import useForm from '@hybris-software/use-ful-form';
import useQuery from '@hybris-software/use-query';

// Icons 
import { RiDeleteBin6Line } from 'react-icons/ri';
import { VscOpenPreview } from 'react-icons/vsc';

// Utils
import dispatcherRole from '../../Utils/dispatcherRole';

// Styles
import Style from './PlatformPopup.module.css'

const PlatformPopup = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [preview, setPreview] = useState(false);
    const [image, setImage] = useState(null);
    const [ratio, setRatio] = useState('16:9');
    const [showSuccess, setShowSuccess] = useState(false);
    const [popupInfo, setPopupInfo] = useState({
        title: '',
        text: '',
    })

    const handleImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result);
        }
        reader.readAsDataURL(file);
        console.log(reader.readAsDataURL(file));
    }

    const form = useForm({
        inputs: {
            title: {
                required: true,
                formatter: (value) => {
                    return value.slice(0, 150);
                },
                validator: (value) => {
                    if (value.length < 3) {
                        return [false, 'Title must be at least 3 characters long and no more than 150 characters.'];
                    }
                    return [true, null];
                }
            },
            text: {
                required: true,
                validator: (value) => {
                    if (value.length < 3) {
                        return [false, 'Text must be at least 3 characters long.'];
                    }
                    return [true, null];
                }
            },
            ratio: {
                required: false,
                value: '16:9',
            },
            picture: {
                required: false,
            },
        },
    });

    const updatePopup = useQuery({
        url: 'administration/popup/',
        method: 'PUT',
        executeImmediately: false,
        clientOptions: {
            timeout: 30000,
            headers: {
                "content-type": "multipart/form-data",
            },
        },
        onSuccess: () => {
            setPopupInfo({
                title: "Success!",
                text: "Popup updated",
            })
            setShowSuccess(true);
        },
        onError: (error) => { },
    });

    const deletePopup = useQuery({
        url: 'administration/popup/',
        method: 'DELETE',
        executeImmediately: false,
        onSuccess: (response) => {
            setPopupInfo({
                title: "Success!",
                text: "Popup deleted",
            })
            setShowSuccess(true);
        },
        onError: (error) => {
        },
    });

    return (
        <PermissionRoute
            loader={< Loader />}
            minimumLoadingTime={1000}
            forLoggedUser={true}
            unAuthorizedAction={() => {
                navigate('/auth/login');
            }}
            permissionController={(response) => {
                dispatcherRole(response.data, location.pathname, navigate);
                return true;
            }}
        >
            <Container>
                {showSuccess && (
                    <GeneralPopup>
                        <div className={Style.popup}>
                            <h4>{popupInfo.title}</h4>
                            <p className={Style.popupText}>{popupInfo.text}</p>
                            <Button onClick={() => setShowSuccess(false)}>Close</Button>
                        </div>
                    </GeneralPopup>
                )}

                {preview &&
                    <GeneralPopup style={{ maxWidth: "500px" }}>
                        <section className={Style.disclaimer}>
                            <div className={Style.header}>
                                <h3>PULSE WORLD</h3>
                                <h6>{form.values.title}</h6>
                            </div>

                            {form.values.picture &&
                                <div
                                    className={Style.backgroundImage}
                                    style={
                                        (ratio === '16:9' && { backgroundImage: `url(${image})`, aspectRatio: '16/9' })
                                        || (ratio === '4:3' && { backgroundImage: `url(${image})`, aspectRatio: '4/3' })
                                        || (ratio === '3:2' && { backgroundImage: `url(${image})`, aspectRatio: '3/2' })
                                        || (ratio === '1:1' && { backgroundImage: `url(${image})`, aspectRatio: '1/1' })
                                    }
                                >
                                </div>
                            }

                            <div className={Style.body}>
                                <p className={Style.site}>{form.values.text.split("\n").map(function (item, i) {
                                    return (
                                        <span key={i}>
                                            {item}
                                            <br />
                                        </span>
                                    )
                                })}
                                </p>
                            </div>

                            <Button
                                className={Style.button}
                                onClick={() => { setPreview(false) }}
                            >
                                Close
                            </Button>
                        </section>
                    </GeneralPopup>
                }

                <Heading viewTitle={'Set platform popup'} />
                <section className={Style.adminPanel}>

                    <Button
                        className={Style.previewButton}
                        disabled={!form.isValid()}
                        onClick={() => { setPreview(true) }}
                    >
                        <VscOpenPreview />
                        See preview popup
                    </Button>

                    <div className={Style.line}>
                        <div className={Style.label}>Title</div>
                        <div className={Style.boxFiled}>
                            <InputField
                                className={Style.inputUsername}
                                type='text'
                                placeholder='Title popup'
                                {...form.getInputProps('title')}
                            />
                        </div>
                    </div>

                    <div className={Style.line}>
                        <div className={Style.label}>Description</div>
                        <div className={Style.boxFiled}>
                            <textarea
                                className={Style.textarea}
                                cols="30"
                                rows="10"
                                placeholder='Text inside the popup'
                                onChange={(e) => { form.getInputProps('text').setValue(e.target.value) }}
                            ></textarea>
                            <div className={Style.error}>{form.getInputProps("text").errorDetails}</div>
                        </div>
                    </div>

                    <div className={Style.line}>
                        <div className={Style.label}>Image</div>
                        <div className={Style.aspectRatio}>
                            <div
                                className={ratio === "16:9" ? Style.active : undefined}
                                onClick={() => { form.getInputProps('ratio').setValue("16:9"); setRatio("16:9") }}
                            >
                                16:9
                            </div>
                            <div
                                className={ratio === "4:3" ? Style.active : undefined}
                                onClick={() => { form.getInputProps('ratio').setValue("4:3"); setRatio("4:3") }}
                            >
                                4:3
                            </div>
                            <div
                                className={ratio === "3:2" ? Style.active : undefined}
                                onClick={() => { form.getInputProps('ratio').setValue("3:2"); setRatio("3:2") }}
                            >
                                3:2
                            </div>
                            <div
                                className={ratio === "1:1" ? Style.active : undefined}
                                onClick={() => { form.getInputProps('ratio').setValue("1:1"); setRatio("1:1") }}
                            >
                                1:1
                            </div>
                        </div>
                        <input
                            id="file"
                            type="file"
                            accept="image/jpg, image/jpeg, image/png, image/gif, image/webp, image/heic"
                            style={{ display: "none" }}
                            onChange={(e) => { form.getInputProps('picture').setValue(e.target.files[0]); handleImage(e) }}
                        />
                        <div className={Style.photo}>
                            <label htmlFor="file" className={Style.uploadPhoto}>
                                {form.values.picture
                                    ? form.values.picture.name
                                    : "Upload photo"
                                }
                            </label>
                            <div
                                className={Style.delete}
                                onClick={() => { form.getInputProps('picture').setValue(null); setImage(null) }}
                            >
                                <RiDeleteBin6Line />
                            </div>
                        </div>
                    </div>

                    <div className={Style.buttons}>
                        <Button
                            isLoading={updatePopup.isLoading || deletePopup.isLoading}
                            onClick={() => {
                                if (form.validate()) {
                                    updatePopup.executeQuery({
                                        ...form.getApiBody(),
                                    });
                                }
                            }}
                        >
                            Update
                        </Button>

                        <Button
                            className={Style.deleteButton}
                            isLoading={updatePopup.isLoading || deletePopup.isLoading}
                            onClick={() => { deletePopup.executeQuery({}) }}
                        >
                            Delete
                        </Button>

                    </div>

                    {updatePopup.error && updatePopup.error.response.status !== 422 && (
                        <div className={Style.error}>
                            An error occurred:{' '}
                            {JSON.stringify(updatePopup.error.response.data)}
                        </div>
                    )}
                </section>
            </Container>
        </PermissionRoute >
    )
}

export default PlatformPopup