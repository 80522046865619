import React, { useState } from 'react'

// Icons
import { IoIosArrowDown } from 'react-icons/io'

// Styles
import Style from "./FilterForTimeDay.module.css";

const FilterForTimeDay = ({ setDay, year, month, day }) => {

    const setListOfDaysInMonth = (month, year) => {
        const daysInMonth = new Date(year, month, 0).getDate();
        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(i);
        }
        return days;
    }

    const [openDropdown, setOpenDropdown] = useState(false);

    return (
        <div
            className={Style.select}
            onClick={() => {
                setOpenDropdown((oldState) => !oldState)
            }}
            onMouseLeave={() => setOpenDropdown(false)}
        >
            <div className={Style.selected}>
                <span>
                    {day}
                </span>
                <span className={openDropdown ? Style.arrowOpened : Style.arrow}>
                    <IoIosArrowDown />
                </span>
            </div>
            <div
                style={openDropdown === true ? { maxHeight: "288px" } : { maxHeight: 0 }}
                className={openDropdown === true ? Style.optionsOpened : Style.options}
            >
                {setListOfDaysInMonth(month, year).map((item, index) => {
                    return (
                        day !== item &&
                        <div
                            key={index}
                            onClick={() => {
                                setDay(item);
                            }}
                        >
                            {item}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FilterForTimeDay