import React, { useContext } from "react";

// Libraries
import { useNavigate } from "react-router-dom";

// Context 
import PermissionsContext from "../../../Contexts/PermissionsContext";

// Icons
import { BiMenuAltLeft } from "react-icons/bi";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { FiLogOut } from "react-icons/fi";

// Styles
import Style from "./Navbar.module.css";

const Navbar = (props) => {

  const navigate = useNavigate();
  const [permissions] = useContext(PermissionsContext);

  return (
    <div className={Style.mainContainer}>
      <div className={Style.content}>
        <div className={Style.contentLeft}>
          <div
            className={Style.menuButton}
            onClick={() => props.setSidebarIsOpen(!props.sidebarIsOpen)}
          >
            {props.sidebarIsOpen ? <BiMenuAltLeft /> : <HiOutlineMenuAlt1 />}
          </div>
          <div className={Style.welcome}>Welcome! <strong>{permissions.username}</strong></div>
        </div>

        <div className={Style.actions} onClick={() => { navigate("/auth/logout"); localStorage.removeItem("token") }}>
          <FiLogOut title="Logout" /> <span>Logout</span>
        </div>
      </div>
    </div >
  );
};

export default Navbar;