import React from 'react'

// Libraries
import { useNavigate } from "react-router-dom";

// Styles
import Style from "./WalletRequest.module.css";

const WalletRequest = ({ withdrawData }) => {

    const navigate = useNavigate();
    const { withdrawCompleted, withdrawCompletedUsersCount, withdrawCompletedOrdersCount, withdrawPending, withdrawPendingUsersCount, withdrawPendingOrdersCount } = withdrawData

    return (
        <section>
            <h2 className={Style.label}>Payout Request</h2>

            <div className={Style.wallet}>
                {/* 
                <div className={Style.card}>
                    <div>
                        Total Airdrop Wallet
                    </div>
                    <div className={Style.stat}>
                        753,159,654.00 XPL
                    </div>
                </div> */}

                <div className={Style.card}>
                    <div className={Style.name}>
                        Payout Approved
                        <span
                            onClick={() => {
                                navigate("/withdraw?&status=completed")
                            }}
                        >View all </span>
                    </div>
                    <div className={Style.stat}>
                        {withdrawCompleted} XPL
                    </div>
                    <div className={Style.onUser}>of {withdrawCompletedUsersCount} Users</div>
                    <div className={Style.onUser}>of {withdrawCompletedOrdersCount} Orders</div>
                </div>

                <div className={Style.card}>
                    <div className={Style.name}>
                        Payout Pending
                        <span
                            onClick={() => {
                                navigate("/withdraw?&status=pending")
                            }}
                        >View all </span>
                    </div>
                    <div className={Style.stat}>
                        {withdrawPending} XPL
                    </div>
                    <div className={Style.onUser}>of {withdrawPendingUsersCount} Users</div>
                    <div className={Style.onUser}>of {withdrawPendingOrdersCount} Orders</div>
                </div>

            </div>

        </section>
    )
}

export default WalletRequest