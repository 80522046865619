import React, { useState, useRef } from 'react';

// Libraries
import { useTable } from 'react-table';
import { Button } from '@hybris-software/ui-kit';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

// icons
import {
  BsChevronRight,
  BsChevronLeft,
  BsChevronDoubleRight,
  BsChevronDoubleLeft,
} from 'react-icons/bs';
import { GoCalendar } from 'react-icons/go';
import { IoMdCloseCircleOutline } from 'react-icons/io';

// Styles
import Style from './GeneralTable.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const CommonStyles = styled.div`
  padding: 1rem;
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tbody tr {
      height: 50px;
      font-size: 13px;
      color: #595959;
      font-weight: 500;
      font-size: 16px;
    }
    th,
    td {
      padding: 20px 0 22px;
      position: relative;
      margin: 0;
      font-weight: 500;
      min-width: 200px;
      max-width: 500px;
      border-bottom: 1px solid #f0f0f0;
      text-align: left;
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const GeneralTable = ({
  data,
  columns,
  height = '750px',
  Styles,
  isLoading,
  setPage,
  setSearch,
  searchField,
  setSearchField,
  sortedBy,
  setSortedBy,
  sortedType,
  setSortedType,
  emptyDataMessage = 'No data available',
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  fitlerForDate,
  filteredDate,
  setFilteredDate,
  setSelectedIds,
  dalaySearch = 1000,
  searchLoading,
  setSearchLoading,
  extraFilters = [],
}) => {
  const ComputedStyles = Styles ? Styles : CommonStyles;

  const [lastType, setLastType] = useState(null);
  const lastTypeRef = useRef(null);
  lastTypeRef.current = lastType;
  const [lastSearch, setLastSearch] = useState('');
  const [lastSearchField, setLastSearchField] = useState(searchField);
  const [paginationInputValue, setPaginationInputValue] = useState(1);

  const filterFieldExistClean = columns
    .filter((col) => col.filterfield)
    .map((col) => col.filterfield);

  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className={Style.datePicker} onClick={onClick} ref={ref}>
      <GoCalendar />
    </div>
  ));

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data.results,
    });

  function handleSort(column) {
    setSortedBy(column.field);
    if (sortedType === 'asc') {
      setSortedType('desc');
    } else {
      setSortedType('asc');
    }
    updatePageNumber(1);
  }

  function updatePageNumber(n) {
    setPage(n);
    setPaginationInputValue(n);
  }

  return (
    <ComputedStyles>
      <div className={Style.tableContainer}>
        <div className={Style.searchBarContainer}>
          {(filterFieldExistClean.length > 0 || extraFilters.length > 0) && (
            <div className={Style.inputSearchContainer}>
              <select
                onChange={(e) => setLastSearchField(e.target.value)}
                className={Style.selectFilter}
              >
                {columns.map((column, i) => {
                  if (column.filterfield) {
                    return (
                      <option key={i} value={column.filterfield}>
                        {column.Header}
                      </option>
                    );
                  }
                  return null;
                })}
                {extraFilters.map((filter, i) => (
                  <option key={'extra' + i} value={filter.key}>
                    {filter.name}
                  </option>
                ))}
              </select>

              <input
                type='text'
                className={Style.inputSearch}
                placeholder='Search...'
                onChange={(e) => {
                  setLastSearch(e.target.value);
                }}
                // Automatic search after 1 second
                // onChange={(e) => {
                //   const deltaTime = dalaySearch;
                //   setSearchLoading(true)

                //   setLastType(new Date().getTime())

                //   setTimeout(() => {
                //     if (new Date().getTime() - lastTypeRef.current > deltaTime) {
                //       setSearch(e.target.value)
                //     }
                //   }, deltaTime)
                // }}
              />

              <Button
                className={Style.searchButton}
                onClick={() => {
                  setSearch(lastSearch);
                  setSearchField(lastSearchField);
                  updatePageNumber(1);
                }}
              >
                Search
              </Button>
            </div>
          )}

          {fitlerForDate && (
            <>
              <div className={Style.customDate}>
                <DatePicker
                  selected={startDate}
                  onChange={(value) => {
                    const [start, end] = value;
                    if (start) setStartDate(start);
                    else setStartDate('');

                    if (end) setEndDate(end);
                    else setEndDate('');
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={<CustomDateInput />}
                  onCalendarClose={() => {
                    if (startDate && endDate) {
                      setFilteredDate([
                        startDate.getFullYear() +
                          '-' +
                          (startDate.getMonth() + 1) +
                          '-' +
                          startDate.getDate(),
                        endDate.getFullYear() +
                          '-' +
                          (endDate.getMonth() + 1) +
                          '-' +
                          endDate.getDate(),
                      ]);
                      updatePageNumber(1);
                    }
                  }}
                />
              </div>

              {filteredDate[0] !== null && (
                <div
                  className={Style.clearDate}
                  title='Clear Date'
                  onClick={() => {
                    setFilteredDate([null, null]);
                    updatePageNumber(1);
                  }}
                >
                  <IoMdCloseCircleOutline />
                </div>
              )}
            </>
          )}
        </div>

        <div className={Style.innerTable}>
          <div style={{ minHeight: height, position: 'relative' }}>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div className={Style.clampedText}>
                          {/* Check Box Col */}
                          {column.checkbox === true && (
                            <input
                              type='checkbox'
                              onClick={(e) => {
                                if (e.target.checked) {
                                  const ids = data.results.map((item) => {
                                    return item.status === 'pending'
                                      ? item.id
                                      : null;
                                  });
                                  // Remove null values
                                  const filteredIds = ids.filter((item) => {
                                    return item !== null;
                                  });
                                  setSelectedIds(filteredIds);
                                } else {
                                  setSelectedIds([]);
                                }
                              }}
                            />
                          )}
                          {column.render('Header')}
                          {!column.checkbox && (
                            <span style={{ position: 'absolute', right: 6 }}>
                              {column.field === sortedBy ? (
                                sortedType === 'asc' ? (
                                  <span
                                    onClick={() => {
                                      handleSort(column);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span
                                      style={{
                                        color: 'var(--main-light)',
                                        marginRight: '-1px',
                                        fontSize: 20,
                                      }}
                                    >
                                      ↑
                                    </span>
                                    <span
                                      style={{ color: 'gray', fontSize: 20 }}
                                    >
                                      ↓
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => {
                                      handleSort(column);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span
                                      style={{
                                        color: 'gray',
                                        marginRight: '-1px',
                                        fontSize: 20,
                                      }}
                                    >
                                      ↑
                                    </span>
                                    <span
                                      style={{
                                        color: 'var(--main-light)',
                                        fontSize: 20,
                                      }}
                                    >
                                      ↓
                                    </span>
                                  </span>
                                )
                              ) : (
                                <span
                                  onClick={() => {
                                    handleSort(column);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <span
                                    style={{
                                      color: 'gray',
                                      marginRight: '-1px',
                                      fontSize: 20,
                                    }}
                                  >
                                    ↑
                                  </span>
                                  <span style={{ color: 'gray', fontSize: 20 }}>
                                    ↓
                                  </span>
                                </span>
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {isLoading || searchLoading ? (
                <div className={Style.tableLoader}></div>
              ) : data.results.length > 0 ? (
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <div className={Style.clampedText}>
                                {cell.render('Cell')}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <div className={Style.noResults}>{emptyDataMessage}</div>
              )}
            </table>
          </div>
        </div>

        <div className={Style.pagination}>
          <div className={Style.entries}>
            Showing {data.from} to {data.to} of {data.count} entries.
            <br />
            {data.totalPages} pages.
          </div>
          <div>
            <div className={Style.paginationButtons}>
              <Button
                disabled={data.links.previous === null || isLoading}
                buttonClassName={Style.buttonLeft}
                disabledClassName={Style.buttonDisabled}
                onClick={() => {
                  if (data?.links.previous !== null) {
                    updatePageNumber(1);
                  }
                }}
              >
                <BsChevronDoubleLeft />
              </Button>
              <Button
                disabled={data.links.previous === null || isLoading}
                buttonClassName={Style.buttonLeft}
                disabledClassName={Style.buttonDisabled}
                onClick={() => {
                  if (data?.links.previous !== null) {
                    updatePageNumber((oldPage) => Math.max(oldPage - 1, 1));
                  }
                }}
              >
                <BsChevronLeft />
              </Button>
              <div className={Style.paginationItem}>{data.page || '-'}</div>
              <Button
                disabled={
                  data === null || data.links.next === null || isLoading
                }
                buttonClassName={Style.buttonRight}
                disabledClassName={Style.buttonDisabled}
                onClick={() => {
                  if (data?.links.next !== null) {
                    updatePageNumber((oldPage) =>
                      Math.min(oldPage + 1, data.totalPages),
                    );
                  }
                }}
              >
                <BsChevronRight />
              </Button>
              <Button
                disabled={
                  data === null || data.links.next === null || isLoading
                }
                buttonClassName={Style.buttonRight}
                disabledClassName={Style.buttonDisabled}
                onClick={() => {
                  if (data?.links.next !== null) {
                    updatePageNumber(data.totalPages);
                  }
                }}
              >
                <BsChevronDoubleRight />
              </Button>
            </div>
            <div className={Style.paginationInputs}>
              <input
                className={Style.paginationInput}
                min={1}
                max={data.page}
                type='number'
                placeholder={data.page || '-'}
                value={paginationInputValue}
                onChange={(e) => {
                  const maxLimited = Math.min(
                    parseInt(e.target.value) || 1,
                    data.totalPages,
                  );
                  const minLimited = Math.max(maxLimited, 1);
                  setPaginationInputValue(minLimited);
                }}
              />
              <Button
                disabled={isLoading}
                className={Style.paginationInputButton}
                onClick={() => {
                  updatePageNumber(paginationInputValue);
                }}
              >
                Go
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ComputedStyles>
  );
};

export default GeneralTable;
