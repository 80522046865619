import React, { /* useState */ } from "react";

// Components
import LoaderGlobal from "../../Components/Commons/LoaderGlobal/LoaderGlobal";

// Libraries
import { Outlet, useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth";

//Images
import PointRight from '../../Assets/images/point-right.png'
import PointLeft from '../../Assets/images/point-left.png'
import LogoBlack from "../../Assets/images/logos/logo-black.png";

// Styles
import Style from "./AuthLayout.module.css";

const AuthLayout = () => {
  const navigate = useNavigate();

  return (
    <AuthRoute
      loader={<LoaderGlobal />}
      minimumLoadingTime={1000}
      forLoggedUser={false}
      action={() => {
        navigate("/");
      }}
    >
      <div className={Style.layoutContainer}>
        <img src={PointRight} alt="" className={Style.pointRight} />
        <img src={PointLeft} alt="" className={Style.pointLeft} />
        <div className={Style.outletContainer}>
          <div className={Style.logo}>
            <img src={LogoBlack} alt="Logo" />
          </div>
          <div className={Style.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default AuthLayout;
