import React from 'react';

// Librarires
import useQuery from '@hybris-software/use-query';

// Components
import Loader from '../../../../Components/Commons/Loader/Loader';

// Styles
import Style from './FinancialReport.module.css';

const FinancialReport = ({ id }) => {
  const reportApi = useQuery({
    url: `administration/users/${id}/report/`,
    method: 'GET',
    executeImmediately: true,
  });

  if (reportApi.isLoading) {
    return <Loader />;
  } else if (reportApi.response) {
    return (
      <div className={Style.popup}>
        <div>
          <strong>PUSD balance: </strong> {reportApi.response.data.pusdBalance}
        </div>
        <div>
          <strong>AUSD balance: </strong>{' '}
          {reportApi.response.data.pWalletBalance}
        </div>
        <div>
          <strong>XPL balance: </strong> {reportApi.response.data.xplBalance}
        </div>
        <div>
          <strong>PUSD deposited: </strong>{' '}
          {reportApi.response.data.pusdDeposited}
        </div>
        <div>
          <strong>PUSD spent: </strong> {reportApi.response.data.pusdSpent}
        </div>
        <div>
          <strong>PUSD sent: </strong> {reportApi.response.data.pusdSent}
        </div>
        <div>
          <strong>PUSD received: </strong>{' '}
          {reportApi.response.data.pusdReceived}
        </div>
        <div>
          <strong>PUSD from OMEGA: </strong>{' '}
          {reportApi.response.data.pusdFromOmega}
        </div>
      </div>
    );
  } else {
    return <p>An error occurred. Retry later</p>;
  }
};

export default FinancialReport;
